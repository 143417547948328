import { HEADER_TOKEN, MY_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from "axios";

export const usersServices = {
  fetchData,
  fetchCountData,
  checkUserVerified,
  toggleActiveDeactiveUser,
  resendInvitation,
  locationUserCheckOutAndVerify,
  addData,
  editData,
  deleteData,
  loginAsUser,
  userLogin,
  userGoogleLogin,
  userLogout,
  verifyUser,
  getNotifications,
  ExportUserData,
  getUserDetails,
  fetchlocationData,
  fetchRoleData,
};

// function fetchData() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/users`, HEADER_TOKEN)
//     .then((response) => response);
// }
// function fetchData(page) {
//   return axios
//     .get(
//       `${MoNGO_DB_API_URL}/users/paginated?pageNumber=${page}&nPerPage=10`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function fetchData(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users?page=${data.pageNumber}&limit=${process.env.REACT_APP_LIMIT}`;
  if (data.role_id) {
    url = `${url}&role_id=${data.role_id}`;
  }
  if (data.lid) {
    url = `${url}&lid=${data.lid}`;
  }
  if (data.name) {
    url = `${url}&q=${data.name}`;
  }
  if (data.userStatus) {
    url = `${url}&userStatus=${data.userStatus}`;
  }
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}

function fetchRoleData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/roles/all`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchlocationData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/${process.env.REACT_APP_PLATFORM}/hr/location`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchCountData(lid) {
  // var data = {
  //   lid: lid,
  // };
  return axios
    .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/payrun/users/count?lid=${lid}`, HEADER_TOKEN)
    .then((response) => response);
}

function resendInvitation(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/invitation/resend`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function toggleActiveDeactiveUser(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/verify/toggle`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function checkUserVerified(uid) {
  var data = {
    uid: uid,
  };
  return axios
    .post(`${MoNGO_DB_API_URL}/users/verify/check`, { data }, HEADER_TOKEN)
    .then((response) => response);
}

function locationUserCheckOutAndVerify(data) {
  return axios
    .post(`${MoNGO_DB_API_URL}/users/location/checkout`, { data }, HEADER_TOKEN)
    .then((response) => response);
}

function userLogin(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/users/login`,
      { data }
    )
    .then((response) => response);
}

function userGoogleLogin(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/users/login-google`,
      { data }
    )
    .then((response) => response);
}

function loginAsUser(value) {
  var data = {
    user_email: value.user_email,
    user_password: value.user_password,
  };
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/login_as_user`,
      { data }
    )
    .then((response) => response);
}

function verifyUser(data) {
  return axios
    .post(`${MoNGO_DB_API_URL}/users/verify`, { data })
    .then((response) => response);
}

function userLogout() {
  return axios
    .post(`${MoNGO_DB_API_URL}/users/logout`, {}, HEADER_TOKEN)
    .then((response) => response);
}

function getNotifications(data) {
  return axios
    .get(`${MoNGO_DB_API_URL}/users/notifications`, HEADER_TOKEN)
    .then((response) => response);
}

// function addData(data) {
//   return axios
//     .post(`${MoNGO_DB_API_URL}/users/mongo/add`, data, HEADER_TOKEN)
//     .then((response) => response);
// }
function addData(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users`,
      data,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function editData(data, id) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/team-roster/${id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function deleteData(uid) {
//   return axios
//     .delete(`${MoNGO_DB_API_URL}/users/${uid}`, HEADER_TOKEN)
//     .then((response) => response);
// }
function deleteData(uid) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/${uid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getUserDetails(uid) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/details/${uid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function ExportUserData() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/users/export`, HEADER_TOKEN)
//     .then((response) => response);
// }
function ExportUserData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/users/export`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
