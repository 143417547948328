import React, { useState } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Avatar,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { schedulerColor } from "../../config";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const useStyles = makeStyles((theme) => ({
  link: {
    padding: "6px 8px",
    borderRadius: ".375rem",
    textDecoration: "none",
    marginBottom: "1rem",
  },
  schedulerActive: {
    backgroundImage: "linear-gradient(45deg, #AA076B, #9F0D9C)",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: schedulerColor.main,
      "& $linkText": { color: "#ffffff" },
      "& $linkIcon": { color: "#ffffff" },
    },
  },
  avatar: {
    height: 25,
    width: 25,
    marginRight: "10px",
  },
  linkText: {
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#0000008a",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
  },
  linkTextActive: { color: "#ffffff" },
  linkTextHidden: { opacity: 0 },
  linkIcon: {
    minWidth: 30,
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color"),
  },
}));

// Helper function to check active state for primary path or additional paths
const isRouteActive = (currentPath, path, additionalPaths = []) => {
  return currentPath === path || additionalPaths.includes(currentPath);
};

export default function SidebarLink({
  countData,
  handleToggleRoutes,
  type,
  isOpen,
  childData,
  clickLink,
  path,
  additionalPaths = [],
  icon,
  image,
  active_image,
  label,
  location,
  isSidebarOpen,
}) {
  const classes = useStyles();
  const [toolTip, setToolTip] = useState(false);

  const openTooltip = () => !isSidebarOpen && setToolTip(true);
  const closeTooltip = () => setToolTip(false);

  const isLinkActive = isRouteActive(location.pathname, path, additionalPaths);

  return (
    <>
      {type === "divider" ? (
        ""
      ) : (
        <>
          <Tooltip
            arrow
            placement="right"
            open={toolTip}
            onClose={closeTooltip}
            onOpen={openTooltip}
            title={<p className={classes.popoverTitle}>{label}</p>}
          >
            {childData && childData.length > 0 ? (
              <ListItem
                onClick={handleToggleRoutes}
                button
                classes={{ root: classnames(classes.link) }}
                disableRipple
              >
                <ListItemText
                  classes={{
                    primary: classnames(classes.linkText, {
                      [classes.linkTextActive]: isLinkActive,
                      [classes.linkTextHidden]: !isSidebarOpen,
                    }),
                  }}
                  primary={label}
                />
                <ListItemIcon style={{ minWidth: "20px" }}>
                  {isOpen ? (
                    <ExpandLessIcon sx={{ fontSize: "30px" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ fontSize: "30px" }} />
                  )}
                </ListItemIcon>
              </ListItem>
            ) : (
              <ListItem
                onClick={clickLink}
                button
                component={path && Link}
                to={path}
                classes={{
                  root: classnames(classes.link, {
                    [classes.schedulerActive]: isLinkActive,
                  }),
                }}
                disableRipple
              >
                <ListItemIcon
                  className={classnames(classes.linkIcon, {
                    [classes.linkIconActive]: isLinkActive,
                  })}
                >
                  {image ? (
                    <Avatar
                      variant="square"
                      className={classes.avatar}
                      src={isLinkActive ? active_image : image}
                    />
                  ) : (
                    icon
                  )}
                </ListItemIcon>

                <ListItemText
                  classes={{
                    primary: classnames(classes.linkText, {
                      [classes.linkTextActive]: isLinkActive,
                      [classes.linkTextHidden]: !isSidebarOpen,
                    }),
                  }}
                  primary={label}
                />
              </ListItem>
            )}
          </Tooltip>
          {childData &&
            isOpen &&
            childData.map((item) => (
              <Tooltip
                arrow
                placement="right"
                open={toolTip}
                onClose={closeTooltip}
                onOpen={openTooltip}
                title={<p className={classes.popoverTitle}>{item.label}</p>}
                key={item.path} // Add a unique key if using map
              >
                <ListItem
                  onClick={clickLink}
                  button
                  component={item.path && Link}
                  to={item.path}
                  classes={{
                    root: classnames(classes.link, {
                      [classes.schedulerActive]: isRouteActive(
                        location.pathname,
                        item.path,
                        item.additionalPaths
                      ),
                    }),
                  }}
                  disableRipple
                >
                  <ListItemIcon
                    className={classnames(classes.linkIcon, {
                      [classes.linkIconActive]: isRouteActive(
                        location.pathname,
                        item.path,
                        item.additionalPaths
                      ),
                    })}
                  >
                    {item.image ? (
                      <Avatar
                        variant="square"
                        className={classes.avatar}
                        src={
                          isRouteActive(
                            location.pathname,
                            item.path,
                            item.additionalPaths
                          )
                            ? item.active_image
                            : item.image
                        }
                      />
                    ) : (
                      icon
                    )}
                  </ListItemIcon>

                  <ListItemText
                    classes={{
                      primary: classnames(classes.linkText, {
                        [classes.linkTextActive]: isRouteActive(
                          location.pathname,
                          item.path,
                          item.additionalPaths
                        ),
                        [classes.linkTextHidden]: !isSidebarOpen,
                      }),
                    }}
                    primary={item.label}
                  />
                </ListItem>
              </Tooltip>
            ))}
        </>
      )}
    </>
  );
}
