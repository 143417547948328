//Dashboard
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
// import { uuid } from "uuidv4";
import { v4 as uuid } from "uuid";
import { DragIndicator as Hamburger } from "@material-ui/icons";
import { stableSort, getComparator } from "../../../utilities/TableFunctions";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { schedulerColor, FILE_URL } from "../../../config";
import { utcToLocal, localTimeToUtc } from "../../../utilities";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import TableDragSelect from "react-table-drag-select";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  Button,
} from "@material-ui/core";
import UserIcon from "@material-ui/icons/Person";
import WbSunnyIcon from "@material-ui/icons/WbSunnyOutlined";
import CloudIcon from "@material-ui/icons/CloudQueue";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  paper: {
    width: "100%",
  },
  table: {
    width: "100%",
  },
  avatarText: {
    float: "left",
    textTransform: "uppercase",
    height: "30px",
    width: "30px",
    fontWeight: 800,
    fontSize: "15px",
    backgroundImage: "linear-gradient(45deg, #AA076B, #9F0D9C)",
    color: "#ffffff",
  },
  userName: {
    marginLeft: "3px",
    maxWidth: "145px",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: schedulerColor.main,
    fontSize: "12px",
    fontWeight: 600,
    float: "left",
    cursor: "move",

    "&:hover": {
      color: "black",
    },
  },
  headTitleActive: {
    color: "white",
    fontWeight: 600,
    fontSize: "40px !important",
  },
  headTitle: {
    color: "gray",
    fontWeight: 100,
    fontSize: "35px !important",
  },
  subTitleActive: {
    fontWeight: 800,
    color: "white",
    textTransform: "uppercase",
    paddingLeft: "3px",
    fontSize: "12px",
  },
  subTitle: {
    fontWeight: 200,
    textTransform: "uppercase",
    color: "gray",
    paddingLeft: "3px",
    fontSize: "12px",
  },
  noEvent: {
    opacity: 0,
    backgroundColor: "#000000",
    color: "#ffffff",
    fontSize: "8px",
    borderRadius: 5,
    padding: "5px",
    "&:hover": {
      backgroundColor: schedulerColor.main,
    },
    margin: "5px 0 5px 0",
  },
  shiftTitle: {
    textAlign: "center",
    fontWeight: 600,
    fontSize: "10px",
    borderRadius: 0,
  },
  myEvent: {
    margin: "1 !important",
    userSelect: "none",
    textAlign: "center",
    color: "#ffffff",
    fontWeight: 600,
    fontSize: "10px",
    borderRadius: 0,
    padding: "12.5px 0px",
    "&:hover": {
      "& $myEventIcon": {
        opacity: 1,
      },
    },
  },
  myEventIcon: {
    cursor: "pointer",
    fontSize: "20px",
    opacity: 0,
    borderRadius: "50%",
  },

  eventUserCell: {
    height: "40px",
    minHeight: "40px",
    padding: "5px 0",
    width: "16%",
    minWidth: "200px",
    // minWidth: "16%",
    maxWidth: "260px",
    border: "1px solid #eeeeee",
  },
  headCell: {
    overflow: "hidden",
    height: "40px",
    minHeight: "40px",
    padding: "10px",
    width: "12%",
    minWidth: "170px",
    // maxWidth: "12%",
    maxWidth: "220px",
    border: "1px solid #eeeeee",
  },

  eventCell: {
    verticalAlign: "top",
    height: "40px",
    minHeight: "40px",
    padding: "0px",
    width: "12%",
    minWidth: "170px",
    // maxWidth: "12%",
    maxWidth: "220px",
    border: "1px solid #eeeeee",
  },
  noEventCell: {
    verticalAlign: "top",
    height: "40px",
    minHeight: "40px",
    padding: "0px",
    width: "12%",
    minWidth: "170px",
    // maxWidth: "12%",
    maxWidth: "220px",
    border: "1px solid #eeeeee",
    textAlign: "center",
    "&:hover": {
      "& $noEvent": {
        opacity: 1,
      },
    },
  },
});

function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.4)"
    );
  }
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      hiddenEmp: true,
    };
  }
  createSortHandler = (event) => {
    const isAsc = this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
    });
  };

  onMouseEnter = (values) => {
    this.setState({
      hoverCell: values,
    });
  };

  onMouseLeave = () => {
    this.setState({
      hoverCell: null,
    });
  };

  hideShowEmployeCollapsed = () => {
    this.setState({
      hiddenEmp: !this.state.hiddenEmp,
    });
  };

  render() {
    const {
      classes,
      permissionsData,
      columns,
      usersData,
      editShiftsModalOpen,
      editEventsModalOpen,
      addShiftsModalOpen,
      deleteShiftsModalOpen,
      onDragStart,
      onDragEnd,
      onRowDragOver,
      onRowDragStart,
      onRowDragEnd,
      hideusers,
      timezone,
      duplicateShifts,
      openUsernameModal,
    } = this.props;
    console.log("chcbdbvkjfvnf", usersData);
    var total_est_wages = 0;
    var total_sch_hours = 0;
    var break_forecast_hrs = 0;
    var open_shifts = 0;
    // var events = 0
    usersData.map((users) => {
      users.myShifts.map((myRows) => {
        if (myRows?.type === "shifts") {
          var breakForCast = myRows.break_forecast_hrs
            ? parseFloat(myRows.break_forecast_hrs)
            : 0;
          break_forecast_hrs =
            parseFloat(break_forecast_hrs) +
            parseFloat(myRows.break_forecast_hrs);
          open_shifts = myRows.open_shifts ? myRows.open_shifts : 0;
          var total_sc_hrs = parseFloat(
            myRows.total_scheduled_hrs ? myRows.total_scheduled_hrs : 0
          );
          total_sch_hours =
            parseFloat(total_sch_hours) +
            parseFloat(myRows.total_scheduled_hrs);
          total_est_wages =
            parseFloat(total_est_wages) +
            parseFloat(myRows.total_scheduled_wage);
        }
      });
    });

    return (
      <Grid container>
        <DragDropContext
          onDragStart={onDragStart}
          // onDragEnd={usersData.map((user) => user.uid === "events" ? false : true)}
          onDragEnd={(result) => onDragEnd(result, columns)}
        >
          <Paper className={classes.paper} style={{ height: "100%" }}>
            <TableContainer>
              {/* <TableDragSelect
              value={this.props.cells}
              onChange={cells => this.setState({ cells })}
              > */}
              <Table stickyHeader className={classes.table} size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center" }}
                      className={classes.eventUserCell}
                    >
                      <b>All Employees ({usersData.length})</b>
                    </TableCell>

                    {columns.map((column) => {
                      return (
                        <TableCell
                          style={{
                            backgroundImage:
                              column.isPast || column.isCurrent
                                ? "linear-gradient(45deg, #AA076B, #9F0D9C)"
                                : "linear-gradient(45deg, rgb(170 7 107 / 23%), rgb(159 13 156 / 23%))",
                          }}
                          className={classes.headCell}
                        >
                          <Typography
                            variant="body2"
                            gutterBottom
                            className={
                              column.isCurrent
                                ? classes.subTitleActive
                                : classes.subTitle
                            }
                          >
                            {column.dayName}
                          </Typography>

                          <Typography
                            variant="body2"
                            className={
                              column.isCurrent
                                ? classes.headTitleActive
                                : classes.headTitle
                            }
                          >
                            {column.getDate}
                          </Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                {/* </Table> */}
                {/* </TableDragSelect> */}
                {/* </TableContainer> */}

                {/* <TableContainer style={{ maxHeight: "auto" }}> */}
                {/* <Table className={classes.table} size="medium"> */}
                <TableBody>
                  {usersData.map((user, uIndex) => {
                    var user_total_hrs = 0;
                    var user_total_wage = 0;
                    var total_scheduled_hrs = 0;
                    var open_shifts = 0;
                    user.myShifts &&
                      user.myShifts.map((myRows) => {
                        if (myRows?.type === "shifts") {
                          let break_forecast_hrs = myRows.break_forecast_hrs
                            ? myRows.break_forecast_hrs
                            : 0;
                          var total_sc_hrs =
                            parseFloat(myRows.total_scheduled_hrs) -
                            this.props.toolbarSettings.break_forecast
                              ? break_forecast_hrs
                              : 0;
                          user_total_hrs =
                            parseFloat(user_total_hrs) +
                            parseFloat(total_sc_hrs);
                          user_total_wage =
                            parseFloat(user_total_wage) +
                            parseFloat(myRows.total_scheduled_wage);
                          total_scheduled_hrs =
                            parseFloat(myRows.total_scheduled_hrs) +
                            parseFloat(total_scheduled_hrs);
                          open_shifts =
                            parseFloat(myRows.open_shifts) +
                            parseFloat(open_shifts);
                        }
                      });
                    return (
                      <TableRow
                        style={{
                          pointerEvents:
                            user.user_name === "Open Shifts" ||
                            this.props.toolbarSettings
                              .scheduled_employees_only === 1
                              ? "none"
                              : "initial",
                        }}
                        onDragOver={() => {
                          onRowDragOver(uIndex);
                        }}
                        tabIndex={-1}
                      >
                        <TableCell
                          draggable={true}
                          className={classes.eventUserCell}
                          onDragStart={(e) => onRowDragStart(e, uIndex)}
                          onDragEnd={onRowDragEnd}
                        >
                          <Grid container>
                            <Grid
                              draggable
                              sm={4}
                              xs={4}
                              style={{
                                paddingLeft: "5px",
                                display: "flex",
                                justifyContent: "start",
                                margin: "auto 0",
                                flexWrap: "wrap",
                              }}
                            >
                              {" "}
                              <IconButton
                                style={{ padding: 0 }}
                                disableFocusRipple
                                disableElevation
                                disableRipple
                              >
                                <Hamburger style={{ fontSize: "18px" }} />
                              </IconButton>
                              {user.user_image ? (
                                <Avatar
                                  src={user.user_image}
                                  className={classes.avatarText}
                                />
                              ) : (
                                <Avatar className={classes.avatarText}>
                                  {user.user_name.charAt(0)}
                                </Avatar>
                              )}
                            </Grid>

                            <Grid
                              style={{ margin: "auto" }}
                              xs={
                                user.user_name === "Open Shifts" ||
                                user.user_name === "Events"
                                  ? 8
                                  : 6
                              }
                              sm={
                                user.user_name === "Open Shifts" ||
                                user.user_name === "Events"
                                  ? 8
                                  : 6
                              }
                            >
                              <Grid container>
                                <Grid sm={12}>
                                  {user.user_name === "Events" ? (
                                    <Typography
                                      style={{ lineHeight: "16px" }}
                                      className={classes.userName}
                                    >
                                      {user.user_name}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      style={{ lineHeight: "16px" }}
                                      className={classes.userName}
                                      onClick={() => {
                                        openUsernameModal(user);
                                      }}
                                    >
                                      {user.user_name}
                                    </Typography>
                                  )}
                                </Grid>

                                {user._id !== 0 && user._id !== "events" ? (
                                  <Grid sm={12}>
                                    <Typography
                                      style={{
                                        lineHeight: "12px",
                                        fontSize: "10px",
                                      }}
                                      variant="body2"
                                    >
                                      {/* {this.props.toolbarSettings.hours_forecast ?
                                        parseFloat(open_shifts).toFixed(2) + "Hrs"
                                        : ""} */}
                                      {this.props.toolbarSettings.hours_forecast
                                        ? parseFloat(
                                            total_scheduled_hrs
                                          ).toFixed(2) + "Hrs"
                                        : ""}
                                      {this.props.toolbarSettings
                                        .hours_forecast &&
                                      this.props.toolbarSettings.wages_forecast
                                        ? " / "
                                        : ""}
                                      {this.props.toolbarSettings.wages_forecast
                                        ? "$" +
                                          parseFloat(user_total_wage).toFixed(2)
                                        : ""}
                                    </Typography>
                                  </Grid>
                                ) : (
                                  ""
                                )}

                                {user._id !== 0 && user._id !== "events" ? (
                                  <Grid sm={12}>
                                    <Typography
                                      style={{
                                        lineHeight: "12px",
                                        fontSize: "10px",
                                      }}
                                      variant="body2"
                                    >
                                      Break Hrs :
                                      {this.props.toolbarSettings.wages_forecast
                                        ? " " +
                                          parseFloat(user_total_hrs).toFixed(
                                            2
                                          ) +
                                          "Hrs"
                                        : ""}
                                    </Typography>
                                  </Grid>
                                ) : (
                                  ""
                                )}

                                {this.props.toolbarSettings
                                  .employee_total_hrs &&
                                user._id !== 0 &&
                                user._id !== "events" ? (
                                  <Grid sm={12}>
                                    <Typography
                                      style={{
                                        lineHeight: "12px",
                                        fontSize: "10px",
                                      }}
                                      variant="body2"
                                    >
                                      {parseFloat(user_total_hrs) > 0
                                        ? parseFloat(user_total_hrs).toFixed(2)
                                        : "0.0"}
                                      &nbsp;Hrs (All Locations)
                                    </Typography>
                                  </Grid>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>
                            {user.user_name === "Open Shifts" ||
                            user.user_name === "Events" ? (
                              ""
                            ) : (
                              <Grid
                                xs={2}
                                sm={2}
                                onClick={() => {
                                  this.props.hideShowEmploye(user, 0);
                                }}
                                style={{ marginTop: "15px" }}
                              >
                                <RemoveRedEyeIcon
                                  htmlColor="#AA076B"
                                  style={{
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                        {columns.map((column, weekDayIndex) => {
                          var totalShifts = user.myShifts.filter((myRows) => {
                            var currentDate = moment(new Date()).format(
                              "YYYY-MM-DD"
                            );
                            return column.date === currentDate;
                          });

                          var onlyShiftsCount = user?.myShifts.filter(
                            (myRows) => {
                              var currentDate = moment
                                .utc(myRows?.currentDate)
                                .format("YYYY-MM-DD");
                              return (
                                column.date === currentDate &&
                                myRows?.type !== "availability"
                              );
                            }
                          );

                          var myItem = {
                            date: column.date,
                            uid: user._id,
                            uuid: uuid(),
                          };

                          return (
                            <Droppable
                              isDropDisabled={
                                column.isPast || column.isCurrent ? true : false
                              }
                              droppableId={JSON.stringify(myItem)}
                              type="list"
                            >
                              {(provided, snapshot) => (
                                <TableCell
                                  className={
                                    totalShifts.length > 0
                                      ? classes.eventCell
                                      : classes.noEventCell
                                  }
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{
                                    cursor: column?.isPast
                                      ? "no-drop"
                                      : "pointer",
                                    pointerEvents: !column.isPast
                                      ? "initial"
                                      : "none",
                                    background: snapshot.isDraggingOver
                                      ? "#eeeeee"
                                      : column.isPast
                                      ? "#1de59514"
                                      : "white",
                                  }}
                                >
                                  {user.userAvailabity?.map(
                                    (availability, index) => {
                                      var preferede_startTime = new Date(
                                        availability.prefered_start_time
                                      );
                                      var preferede_startTimeee =
                                        preferede_startTime.toLocaleString(
                                          "en-US"
                                        );
                                      var prefered_start_time = moment(
                                        preferede_startTimeee
                                      ).format("hh:mm A");

                                      var preferede_endTime = new Date(
                                        availability.prefered_end_time
                                      );
                                      var preferede_endTimeee =
                                        preferede_endTime.toLocaleString(
                                          "en-US"
                                        );
                                      var prefered_end_time =
                                        moment(preferede_endTimeee).format(
                                          "hh:mm A"
                                        );

                                      var PreferedTime =
                                        prefered_start_time +
                                        "-" +
                                        prefered_end_time;

                                      var unavailable_startTime = new Date(
                                        availability.unavailable_start_time
                                      );
                                      var unavailable_startTimeee =
                                        unavailable_startTime.toLocaleString(
                                          "en-US"
                                        );
                                      var unavailable_start_time = moment(
                                        unavailable_startTimeee
                                      ).format("hh:mm A");

                                      var unavailable_endTime = new Date(
                                        availability.unavailable_end_time
                                      );
                                      var unavailable_endTimeee =
                                        unavailable_endTime.toLocaleString(
                                          "en-US"
                                        );
                                      var unavailable_end_time = moment(
                                        unavailable_endTimeee
                                      ).format("hh:mm A");

                                      var unavailableTime =
                                        unavailable_start_time +
                                        "-" +
                                        unavailable_end_time;

                                      return weekDayIndex ===
                                        availability.day_id ? (
                                        <Grid
                                          // onClick={() => {
                                          //   addShiftsModalOpen(
                                          //     user._id,
                                          //     column.date
                                          //   );
                                          // }}
                                          justifyContent="center"
                                          container
                                          // className={classes.myEvent}
                                          // ref={provided.innerRef}
                                          // {...provided.dragHandleProps}
                                          // {...provided.draggableProps}
                                          style={{
                                            //   pointerEvents:
                                            //     !availability.is_prefered &&
                                            //     !availability.is_wholeday
                                            //       ? "none"
                                            //       : "initial",
                                            //   padding: "8px",

                                            //   ...provided.draggableProps?.style,
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "100%",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <Grid
                                            item
                                            sm={12}
                                            style={{
                                              width: "100%",
                                              height: "40px",
                                              margin: "3px",
                                              background:
                                                "rgba(170, 7, 107, 0.125)",
                                              padding: "3px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {availability.prefered_start_time ===
                                              null &&
                                            availability.prefered_end_time ===
                                              null ? (
                                              ""
                                            ) : (
                                              <Typography
                                                className={classes.shiftTitle}
                                                style={{
                                                  lineHeight: "15px",
                                                  fontSize: "12px",
                                                  width: "100%",
                                                  color: "#AA076B",
                                                  fontWeight: "normal",
                                                }}
                                                variant="h6"
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Preferred
                                                </span>{" "}
                                                <br />
                                                {PreferedTime}
                                              </Typography>
                                            )}
                                            {availability.unavailable_start_time ===
                                              null &&
                                            availability.unavailable_end_time ===
                                              null ? (
                                              ""
                                            ) : (
                                              <Typography
                                                className={classes.shiftTitle}
                                                style={{
                                                  lineHeight: "15px",
                                                  fontSize: "12px",
                                                  width: "100%",
                                                  color: "#AA076B",
                                                  fontWeight: "normal",
                                                }}
                                                variant="h6"
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Unavailable
                                                </span>{" "}
                                                <br />
                                                {unavailableTime}
                                              </Typography>
                                            )}
                                            {availability.unavailable_start_time ===
                                              null &&
                                            availability.unavailable_end_time ===
                                              null &&
                                            availability.prefered_start_time ===
                                              null &&
                                            availability.prefered_end_time ===
                                              null ? (
                                              <Typography
                                                className={classes.shiftTitle}
                                                style={{
                                                  lineHeight: "15px",
                                                  fontSize: "12px",
                                                  width: "100%",
                                                  color: "#AA076B",
                                                  fontWeight: "normal",
                                                }}
                                                variant="h6"
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Available whole day
                                                </span>{" "}
                                                <br />
                                              </Typography>
                                            ) : (
                                              ""
                                            )}
                                          </Grid>
                                        </Grid>
                                      ) : (
                                        <></>
                                      );
                                    }
                                  )}

                                  {column.date !==
                                  moment(new Date()).format("YYYY-MM-DD") ? (
                                    snapshot.isDraggingOver ? (
                                      ""
                                    ) : totalShifts.length > 0 ? (
                                      ""
                                    ) : user.uid === "events" ? (
                                      <Button
                                        disabled={
                                          permissionsData.scheduler_schedule_add_events &&
                                          column.date !==
                                            moment(new Date()).format(
                                              "YYYY-MM-DD"
                                            )
                                            ? false
                                            : true
                                        }
                                        onClick={() => {
                                          addShiftsModalOpen(
                                            user.uid === "events"
                                              ? user.uid
                                              : user.uid === "0"
                                              ? user.uid
                                              : user._id,
                                            column.date
                                          );
                                        }}
                                        className={classes.noEvent}
                                        variant="contained"
                                      >
                                        Add
                                      </Button>
                                    ) : (
                                      <Button
                                        disabled={
                                          permissionsData.scheduler_schedule_add_shifts &&
                                          column.date !==
                                            moment(new Date()).format(
                                              "YYYY-MM-DD"
                                            )
                                            ? false
                                            : true
                                        }
                                        onClick={() => {
                                          addShiftsModalOpen(
                                            user.uid === "events"
                                              ? user.uid
                                              : user.uid === "0"
                                              ? user.uid
                                              : user._id,
                                            column.date
                                          );
                                        }}
                                        className={classes.noEvent}
                                        variant="contained"
                                      >
                                        Add
                                      </Button>
                                    )
                                  ) : (
                                    ""
                                  )}

                                  {user.myShifts.map((item, index) => {
                                    let currentDate = moment
                                      .utc(item?.currentDate)
                                      .format("YYYY-MM-DD");

                                    var myBg = hexToRgbA(item?.role_bgcolor);
                                    // var startTime = moment(localTimeToUtc(item.startTime)).format("hh:mm A")
                                    // var endTime = moment(localTimeToUtc(item.endTime)).format("hh:mm A")
                                    var startTimeold = new Date(
                                      item?.startTime
                                    );
                                    var startTimeee =
                                      startTimeold.toLocaleString("en-US", {
                                        // timeZone:
                                        //   this.props.timezone[0]
                                        //     .location_timezone,
                                      });

                                    var startTime =
                                      moment(startTimeee).format("hh:mm A");

                                    var endTimeold = new Date(item.endTime);
                                    var endTimeee = endTimeold.toLocaleString(
                                      "en-US"
                                      // {
                                      //   timeZone:
                                      //     this.props.timezone[0]
                                      //       .location_timezone,
                                      // }
                                    );
                                    var endTime =
                                      moment(endTimeee).format("hh:mm A");

                                    var shiftTitle = startTime + "-" + endTime;
                                    if (currentDate === column.date)
                                      return (
                                        <Draggable
                                          isDragDisabled={
                                            item.type === "availability"
                                              ? true
                                              : item.clock_in
                                              ? true
                                              : !column.isPast &&
                                                !column.isCurrent &&
                                                permissionsData.scheduler_schedule_drag
                                              ? false
                                              : true
                                          }
                                          draggableId={JSON.stringify(item)}
                                          index={item.currentDate}
                                        >
                                          {(provided, snapshot) => {
                                            return item.type === "shifts" ? (
                                              <Grid
                                                //justifyContent="space-between"
                                                // container
                                                className={classes.myEvent}
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                                style={{
                                                  border: `2px solid ${item.role_bgcolor}`,
                                                  backgroundColor:
                                                    item.isPublished
                                                      ? item.role_bgcolor
                                                      : myBg,
                                                  ...provided.draggableProps
                                                    .style,
                                                }}
                                              >
                                                <Grid
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    flexDirection: "row",
                                                  }}
                                                >
                                                  <Grid item>
                                                    {
                                                      permissionsData.scheduler_schedule_add_shifts &&
                                                      column.date !==
                                                        moment(
                                                          new Date()
                                                        ).format(
                                                          "YYYY-MM-DD"
                                                        ) ? (
                                                        <AddIcon
                                                          style={{
                                                            float: "left",
                                                          }}
                                                          onClick={() => {
                                                            duplicateShifts(
                                                              user.uid ===
                                                                "events"
                                                                ? user.uid
                                                                : user.uid ===
                                                                  "0"
                                                                ? user.uid
                                                                : user._id,
                                                              item
                                                            );
                                                          }}
                                                          className={
                                                            classes.myEventIcon
                                                          }
                                                        />
                                                      ) : (
                                                        ""
                                                      )
                                                      // (
                                                      //   <AddIcon
                                                      //     style={{
                                                      //       float: "left",
                                                      //     }}
                                                      //     // onClick={() => { duplicateShifts(user.uid === 'events' ? user.uid : user.uid === "0" ? user.uid : user._id, item) }}
                                                      //     className={
                                                      //       classes.myEventIcon
                                                      //     }
                                                      //   />
                                                      // )
                                                    }
                                                  </Grid>
                                                  <Grid item>
                                                    {column.date ===
                                                    moment(new Date()).format(
                                                      "YYYY-MM-DD"
                                                    ) ? (
                                                      <Typography
                                                        style={{
                                                          color:
                                                            item.isPublished
                                                              ? "#FFFFFF"
                                                              : "#000000",
                                                        }}
                                                        className={
                                                          classes.shiftTitle
                                                        }
                                                        // onClick={() => { editShiftsModalOpen(item) }}
                                                      >
                                                        {shiftTitle}
                                                      </Typography>
                                                    ) : (
                                                      <Typography
                                                        style={{
                                                          color:
                                                            item.isPublished
                                                              ? "#FFFFFF"
                                                              : "#000000",
                                                        }}
                                                        className={
                                                          classes.shiftTitle
                                                        }
                                                        onClick={() => {
                                                          editShiftsModalOpen(
                                                            item
                                                          );
                                                        }}
                                                      >
                                                        {shiftTitle}
                                                      </Typography>
                                                    )}
                                                  </Grid>
                                                  <Grid item>
                                                    {permissionsData.scheduler_schedule_delete_shifts &&
                                                    !item.clock_in &&
                                                    column.date !==
                                                      moment(new Date()).format(
                                                        "YYYY-MM-DD"
                                                      ) ? (
                                                      <DeleteIcon
                                                        // disabled={column.date !== moment(new Date()).format("YYYY-MM-DD") ? false : true}
                                                        style={{
                                                          float: "right",
                                                        }}
                                                        onClick={() => {
                                                          deleteShiftsModalOpen(
                                                            item
                                                          );
                                                        }}
                                                        className={
                                                          classes.myEventIcon
                                                        }
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </Grid>
                                                </Grid>

                                                {column.date ===
                                                moment(new Date()).format(
                                                  "YYYY-MM-DD"
                                                ) ? (
                                                  <Typography
                                                    className={
                                                      classes.shiftTitle
                                                    }
                                                    style={{
                                                      textAlign: "center",
                                                      color: item.isPublished
                                                        ? "#FFFFFF"
                                                        : "#000000",
                                                      marginInline: "auto",
                                                    }}
                                                    // onClick={() => { editShiftsModalOpen(item) }}
                                                  >
                                                    {item.statusLabel}
                                                  </Typography>
                                                ) : (
                                                  <Typography
                                                    className={
                                                      classes.shiftTitle
                                                    }
                                                    style={{
                                                      textAlign: "center",
                                                      color: item.isPublished
                                                        ? "#FFFFFF"
                                                        : "#000000",
                                                      marginInline: "auto",
                                                    }}
                                                    onClick={() => {
                                                      editShiftsModalOpen(item);
                                                    }}
                                                  >
                                                    {item.statusLabel}
                                                  </Typography>
                                                )}

                                                {column.date ===
                                                moment(new Date()).format(
                                                  "YYYY-MM-DD"
                                                ) ? (
                                                  <Typography
                                                    className={
                                                      classes.shiftTitle
                                                    }
                                                    style={{ color: "black" }}
                                                    // onClick={() => { editShiftsModalOpen(item) }}
                                                  >
                                                    {item.iscover === 1
                                                      ? "Cover Request Pending"
                                                      : ""}
                                                  </Typography>
                                                ) : (
                                                  <Typography
                                                    className={
                                                      classes.shiftTitle
                                                    }
                                                    style={{ color: "black" }}
                                                    onClick={() => {
                                                      editShiftsModalOpen(item);
                                                    }}
                                                  >
                                                    {item.iscover === 1
                                                      ? "Cover Request Pending"
                                                      : ""}
                                                  </Typography>
                                                )}

                                                {column.date ===
                                                moment(new Date()).format(
                                                  "YYYY-MM-DD"
                                                ) ? (
                                                  <Typography
                                                    className={
                                                      classes.shiftTitle
                                                    }
                                                    style={{ color: "black" }}
                                                    // onClick={() => { editShiftsModalOpen(item) }}
                                                  >
                                                    {item.istimeoff === 1
                                                      ? "TimeOff Request Pending"
                                                      : ""}
                                                  </Typography>
                                                ) : (
                                                  <Typography
                                                    className={
                                                      classes.shiftTitle
                                                    }
                                                    style={{ color: "black" }}
                                                    onClick={() => {
                                                      editShiftsModalOpen(item);
                                                    }}
                                                  >
                                                    {item.istimeoff === 1
                                                      ? "TimeOff Request Pending"
                                                      : ""}
                                                  </Typography>
                                                )}

                                                {column.date ===
                                                moment(new Date()).format(
                                                  "YYYY-MM-DD"
                                                ) ? (
                                                  <Typography
                                                    className={
                                                      classes.shiftTitle
                                                    }
                                                    style={{ color: "black" }}
                                                    // onClick={() => { editShiftsModalOpen(item) }}
                                                  >
                                                    {item.istrade === 1
                                                      ? "Trade Request Pending"
                                                      : ""}
                                                  </Typography>
                                                ) : (
                                                  <Typography
                                                    className={
                                                      classes.shiftTitle
                                                    }
                                                    style={{ color: "black" }}
                                                    onClick={() => {
                                                      editShiftsModalOpen(item);
                                                    }}
                                                  >
                                                    {item.istrade === 1
                                                      ? "Trade Request Pending"
                                                      : ""}
                                                  </Typography>
                                                )}

                                                {item.is_conflict_other_lid ===
                                                1 ? (
                                                  <Typography
                                                    className={
                                                      classes.shiftTitle
                                                    }
                                                    style={{
                                                      textAlign: "center",
                                                      color: "red",
                                                      fontWeight: "bold",
                                                      width: " 55px",
                                                      marginInline: "auto",
                                                    }}
                                                    onClick={() => {
                                                      editShiftsModalOpen(item);
                                                    }}
                                                  >
                                                    Conflict
                                                  </Typography>
                                                ) : (
                                                  <Typography
                                                    className={
                                                      classes.shiftTitle
                                                    }
                                                    style={{
                                                      textAlign: "center",
                                                      color: "black",
                                                      width: " 55px",
                                                      marginInline: "auto",
                                                    }}
                                                    // onClick={() => { editShiftsModalOpen(item) }}
                                                  ></Typography>
                                                )}
                                              </Grid>
                                            ) : item.type === "availability" ? (
                                              <></>
                                            ) : (
                                              <Grid
                                                justifyContent="center"
                                                container
                                                className={classes.myEvent}
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                                style={{
                                                  cursor: "pointer",
                                                  padding: "12.5px 0px",
                                                  border: `2px solid rgb(245, 232, 245)`,
                                                  backgroundColor:
                                                    "rgb(245, 232, 245)",
                                                  ...provided.draggableProps
                                                    .style,
                                                }}
                                              >
                                                <Typography
                                                  onClick={() => {
                                                    permissionsData.scheduler_schedule_edit_events &&
                                                      editEventsModalOpen(item);
                                                  }}
                                                  style={{
                                                    color: "#000000",
                                                    width: "88px",
                                                  }}
                                                  className={classes.shiftTitle}
                                                >
                                                  {item.title}
                                                </Typography>
                                                {/* <br/>
                                                <Typography
                                                      style={{
                                                        color: "#000000",
                                                        marginTop: "10px",
                                                      }}
                                                      className={
                                                        classes.shiftTitle
                                                      }
                                                    >
                                                      {item.description}
                                                    </Typography> */}
                                                <Grid item>
                                                  {permissionsData.scheduler_schedule_delete_events ? (
                                                    <DeleteIcon
                                                      // disabled={column.date !== moment(new Date()).format("YYYY-MM-DD") ? false : true}
                                                      style={{
                                                        float: "right",
                                                      }}
                                                      onClick={() => {
                                                        this.props.deleteEventModalOpen(
                                                          item
                                                        );
                                                      }}
                                                      className={
                                                        classes.myEventIcon
                                                      }
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </Grid>
                                              </Grid>
                                            );
                                          }}
                                        </Draggable>
                                      );
                                  })}
                                  {provided.placeholder}
                                </TableCell>
                              )}
                            </Droppable>
                          );
                        })}
                      </TableRow>
                    );
                  })}

                  {/* Hide userss */}
                  {hideusers.length > 0 && (
                    <TableRow
                      onClick={this.hideShowEmployeCollapsed}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell
                        style={{ background: "#AA076B", color: "white" }}
                      >
                        Hidden Employee
                        <KeyboardArrowDownIcon htmlColor="white" />
                      </TableCell>

                      {/* Render the TableCell component 7 times */}
                      {[...Array(7)].map((_, index) => (
                        <TableCell
                          key={index}
                          style={{ background: "#AA076B" }}
                        ></TableCell>
                      ))}

                      <TableCell></TableCell>
                    </TableRow>
                  )}
                  {this.state.hiddenEmp === true &&
                    hideusers.map((user, uIndex) => {
                      var user_total_hrs = 0;
                      var user_total_wage = 0;
                      var total_scheduled_hrs = 0;
                      var open_shifts = 0;
                      user.myShifts &&
                        user.myShifts.map((myRows) => {
                          if (myRows?.type === "shifts") {
                            let break_forecast_hrs = myRows.break_forecast_hrs
                              ? myRows?.break_forecast_hrs
                              : 0;
                            var total_sc_hrs =
                              parseFloat(myRows?.total_scheduled_hrs) -
                              this.props.toolbarSettings.break_forecast
                                ? break_forecast_hrs
                                : 0;
                            user_total_hrs =
                              parseFloat(user_total_hrs) +
                              parseFloat(total_sc_hrs);
                            user_total_wage =
                              parseFloat(user_total_wage) +
                              parseFloat(myRows?.total_scheduled_wage);
                            total_scheduled_hrs =
                              parseFloat(myRows?.total_scheduled_hrs) +
                              parseFloat(total_scheduled_hrs);
                            open_shifts =
                              parseFloat(myRows?.open_shifts) +
                              parseFloat(open_shifts);
                          }
                        });
                      return (
                        <TableRow
                          style={{
                            pointerEvents:
                              user.user_name === "Open Shifts"
                                ? "none"
                                : "initial",

                            // this.props.toolbarSettings
                            //   .scheduled_employees_only === 1
                          }}
                          onDragOver={() => {
                            this.props.onRowDragOverHide(uIndex);
                          }}
                          tabIndex={-1}
                        >
                          <TableCell
                            style={{ cursor: "move" }}
                            onDragStart={(e) =>
                              this.props.onRowDragStartHide(e, uIndex)
                            }
                            onDragEnd={this.props.onRowDragEndHide}
                            className={classes.eventUserCell}
                          >
                            <Grid container>
                              <Grid
                                sm={4}
                                xs={4}
                                style={{
                                  paddingLeft: "5px",
                                  display: "flex",
                                  justifyContent: "start",
                                  margin: "auto 0",
                                  flexWrap: "wrap",
                                }}
                              >
                                {" "}
                                <IconButton
                                  style={{ padding: 0 }}
                                  disableFocusRipple
                                  disableElevation
                                  disableRipple
                                >
                                  <Hamburger style={{ fontSize: "18px" }} />
                                </IconButton>
                                {user.user_image ? (
                                  <Avatar
                                    src={user.user_image}
                                    className={classes.avatarText}
                                  />
                                ) : (
                                  <Avatar className={classes.avatarText}>
                                    {user.user_name.charAt(0)}
                                  </Avatar>
                                )}
                              </Grid>

                              <Grid style={{ margin: "auto" }} xs={6} sm={6}>
                                <Grid container>
                                  <Grid sm={12}>
                                    {user.user_name === "Events" ? (
                                      <Typography
                                        style={{
                                          lineHeight: "16px",
                                        }}
                                        className={classes.userName}
                                      >
                                        {user.user_name}
                                      </Typography>
                                    ) : (
                                      <Typography
                                        style={{
                                          lineHeight: "16px",
                                        }}
                                        className={classes.userName}
                                        // onClick={() => {
                                        //   openUsernameModal(user);
                                        // }}
                                      >
                                        {user.user_name}
                                      </Typography>
                                    )}
                                  </Grid>

                                  {user._id !== 0 && user._id !== "events" ? (
                                    <Grid sm={12}>
                                      <Typography
                                        style={{
                                          lineHeight: "12px",
                                          fontSize: "10px",
                                        }}
                                        variant="body2"
                                      >
                                        {this.props.toolbarSettings
                                          .hours_forecast
                                          ? parseFloat(
                                              total_scheduled_hrs
                                            ).toFixed(2) + "Hrs"
                                          : ""}
                                        {this.props.toolbarSettings
                                          .hours_forecast &&
                                        this.props.toolbarSettings
                                          .wages_forecast
                                          ? " / "
                                          : ""}
                                        {this.props.toolbarSettings
                                          .wages_forecast
                                          ? "$" +
                                            parseFloat(user_total_wage).toFixed(
                                              2
                                            )
                                          : ""}
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}

                                  {user._id !== 0 && user._id !== "events" ? (
                                    <Grid sm={12}>
                                      <Typography
                                        style={{
                                          lineHeight: "12px",
                                          fontSize: "10px",
                                        }}
                                        variant="body2"
                                      >
                                        Break Hrs :
                                        {this.props.toolbarSettings
                                          .wages_forecast
                                          ? " " +
                                            parseFloat(user_total_hrs).toFixed(
                                              2
                                            ) +
                                            "Hrs"
                                          : ""}
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}

                                  {this.props.toolbarSettings
                                    .employee_total_hrs &&
                                  user._id !== 0 &&
                                  user._id !== "events" ? (
                                    <Grid sm={12}>
                                      <Typography
                                        style={{
                                          lineHeight: "12px",
                                          fontSize: "10px",
                                        }}
                                        variant="body2"
                                      >
                                        {parseFloat(user_total_hrs) > 0
                                          ? parseFloat(user_total_hrs).toFixed(
                                              2
                                            )
                                          : "0.0"}
                                        &nbsp;Hrs (All Locations)
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                              </Grid>

                              <Grid
                                onClick={() => {
                                  this.props.hideShowEmploye(user, 1);
                                }}
                                xs={2}
                                sm={2}
                                style={{ marginTop: "15px" }}
                              >
                                <VisibilityOffIcon
                                  htmlColor="#AA076B"
                                  style={{
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </TableCell>
                          {columns.map((column, weekDayIndex) => {
                            var totalShifts = user.myShifts.filter((myRows) => {
                              var currentDate = moment
                                .utc(myRows?.currentDate)
                                .format("YYYY-MM-DD");
                              return column.date === currentDate;
                            });

                            var myItem = {
                              date: column.date,
                              uid: user._id,
                              uuid: uuid(),
                            };
                            return (
                              <Droppable
                                isDropDisabled={
                                  column.isPast || column.isCurrent
                                    ? true
                                    : false
                                }
                                droppableId={JSON.stringify(myItem)}
                                type="list"
                              >
                                {(provided, snapshot) => (
                                  <TableCell
                                    className={
                                      totalShifts.length > 0
                                        ? classes.eventCell
                                        : classes.noEventCell
                                    }
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                      cursor: column.isPast
                                        ? "no-drop"
                                        : "pointer",
                                      pointerEvents: !column.isPast
                                        ? "initial"
                                        : "none",
                                      background: snapshot.isDraggingOver
                                        ? "#eeeeee"
                                        : column.isPast
                                        ? "#1de59514"
                                        : "white",
                                    }}
                                  >
                                    {/* {snapshot.isDraggingOver ? (
                                        ""
                                      ) : totalShifts.length > 0 ? (
                                        ""
                                      ) : (
                                        <Button
                                          disabled={
                                            permissionsData.scheduler_schedule_add_shifts &&
                                            column.date !==
                                              moment(new Date()).format(
                                                "YYYY-MM-DD"
                                              )
                                              ? false
                                              : true
                                          }
                                          onClick={() => {
                                            addShiftsModalOpen(
                                              user.uid === "events"
                                                ? user.uid
                                                : user.uid === "0"
                                                ? user.uid
                                                : user._id,
                                              column.date
                                            );
                                          }}
                                          className={classes.noEvent}
                                          variant="contained"
                                        >
                                          Add
                                        </Button>
                                      )} */}

                                    {user.userAvailabity?.map(
                                      (availability, index) => {
                                        var preferede_startTime = new Date(
                                          availability.prefered_start_time
                                        );
                                        var preferede_startTimeee =
                                          preferede_startTime.toLocaleString(
                                            "en-US"
                                          );
                                        var prefered_start_time = moment(
                                          preferede_startTimeee
                                        ).format("hh:mm A");

                                        var preferede_endTime = new Date(
                                          availability.prefered_end_time
                                        );
                                        var preferede_endTimeee =
                                          preferede_endTime.toLocaleString(
                                            "en-US"
                                          );
                                        var prefered_end_time =
                                          moment(preferede_endTimeee).format(
                                            "hh:mm A"
                                          );

                                        var PreferedTime =
                                          prefered_start_time +
                                          "-" +
                                          prefered_end_time;

                                        var unavailable_startTime = new Date(
                                          availability.unavailable_start_time
                                        );
                                        var unavailable_startTimeee =
                                          unavailable_startTime.toLocaleString(
                                            "en-US"
                                          );
                                        var unavailable_start_time = moment(
                                          unavailable_startTimeee
                                        ).format("hh:mm A");

                                        var unavailable_endTime = new Date(
                                          availability.unavailable_end_time
                                        );
                                        var unavailable_endTimeee =
                                          unavailable_endTime.toLocaleString(
                                            "en-US"
                                          );
                                        var unavailable_end_time = moment(
                                          unavailable_endTimeee
                                        ).format("hh:mm A");

                                        var unavailableTime =
                                          unavailable_start_time +
                                          "-" +
                                          unavailable_end_time;

                                        return weekDayIndex ===
                                          availability.day_id ? (
                                          <Grid
                                            // onClick={() => {
                                            //   addShiftsModalOpen(
                                            //     user._id,
                                            //     column.date
                                            //   );
                                            // }}
                                            justifyContent="center"
                                            container
                                            // className={classes.myEvent}
                                            // ref={provided.innerRef}
                                            // {...provided.dragHandleProps}
                                            // {...provided.draggableProps}
                                            style={{
                                              //   pointerEvents:
                                              //     !availability.is_prefered &&
                                              //     !availability.is_wholeday
                                              //       ? "none"
                                              //       : "initial",
                                              //   padding: "8px",

                                              //   ...provided.draggableProps?.style,
                                              display: "flex",
                                              justifyContent: "center",
                                              width: "100%",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <Grid
                                              item
                                              sm={12}
                                              style={{
                                                width: "100%",
                                                height: "40px",
                                                margin: "3px",
                                                background:
                                                  "rgba(170, 7, 107, 0.125)",
                                                padding: "3px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {availability.prefered_start_time ===
                                                null &&
                                              availability.prefered_end_time ===
                                                null ? (
                                                ""
                                              ) : (
                                                <Typography
                                                  className={classes.shiftTitle}
                                                  style={{
                                                    lineHeight: "15px",
                                                    fontSize: "12px",
                                                    width: "100%",
                                                    color: "#AA076B",
                                                    fontWeight: "normal",
                                                  }}
                                                  variant="h6"
                                                >
                                                  <span
                                                    style={{
                                                      fontWeight: 700,
                                                    }}
                                                  >
                                                    Preferred
                                                  </span>{" "}
                                                  <br />
                                                  {PreferedTime}
                                                </Typography>
                                              )}
                                              {availability.unavailable_start_time ===
                                                null &&
                                              availability.unavailable_end_time ===
                                                null ? (
                                                ""
                                              ) : (
                                                <Typography
                                                  className={classes.shiftTitle}
                                                  style={{
                                                    lineHeight: "15px",
                                                    fontSize: "12px",
                                                    width: "100%",
                                                    color: "#AA076B",
                                                    fontWeight: "normal",
                                                  }}
                                                  variant="h6"
                                                >
                                                  <span
                                                    style={{
                                                      fontWeight: 700,
                                                    }}
                                                  >
                                                    Unavailable
                                                  </span>{" "}
                                                  <br />
                                                  {unavailableTime}
                                                </Typography>
                                              )}
                                              {availability.unavailable_start_time ===
                                                null &&
                                              availability.unavailable_end_time ===
                                                null &&
                                              availability.prefered_start_time ===
                                                null &&
                                              availability.prefered_end_time ===
                                                null ? (
                                                <Typography
                                                  className={classes.shiftTitle}
                                                  style={{
                                                    lineHeight: "15px",
                                                    fontSize: "12px",
                                                    width: "100%",
                                                    color: "#AA076B",
                                                    fontWeight: "normal",
                                                  }}
                                                  variant="h6"
                                                >
                                                  <span
                                                    style={{
                                                      fontWeight: 700,
                                                    }}
                                                  >
                                                    Available whole day
                                                  </span>{" "}
                                                  <br />
                                                </Typography>
                                              ) : (
                                                ""
                                              )}
                                            </Grid>
                                          </Grid>
                                        ) : (
                                          <></>
                                        );
                                      }
                                    )}

                                    {user.myShifts.map((item, index) => {
                                      moment.tz.setDefault(
                                        this.props.timezone[0].location_timezone
                                      );
                                      let currentDate = moment
                                        .utc(item.currentDate)
                                        .format("YYYY-MM-DD");
                                      var myBg = hexToRgbA(item.role_bgcolor);
                                      // var startTime = moment(localTimeToUtc(item.startTime)).format("hh:mm A")
                                      // var endTime = moment(localTimeToUtc(item.endTime)).format("hh:mm A")

                                      var startTimeold = new Date(
                                        item.startTime
                                      );
                                      var startTimeee =
                                        startTimeold.toLocaleString("en-US", {
                                          // timeZone:
                                          //   this.props.timezone[0]
                                          //     .location_timezone,
                                        });
                                      var startTimeeeee =
                                        moment(startTimeee).format("hh:mm A");
                                      var endTimeold = new Date(item.endTime);
                                      var endTimeee = endTimeold.toLocaleString(
                                        "en-US",
                                        {
                                          // timeZone:
                                          //   this.props.timezone[0]
                                          //     .location_timezone,
                                        }
                                      );
                                      var endTimeeeeee =
                                        moment(endTimeee).format("hh:mm A");
                                      var startTime = startTimeeeee;
                                      var endTime = endTimeeeeee;

                                      var shiftTitle =
                                        startTime + "-" + endTime;
                                      if (currentDate === column.date)
                                        return (
                                          <Draggable
                                            isDragDisabled={
                                              item.type === "availability"
                                                ? true
                                                : item.clock_in
                                                ? true
                                                : !column.isPast &&
                                                  !column.isCurrent &&
                                                  permissionsData.scheduler_schedule_drag
                                                ? false
                                                : true
                                            }
                                            draggableId={JSON.stringify(item)}
                                            index={item.currentDate}
                                          >
                                            {(provided, snapshot) => {
                                              return item.type === "shifts" ? (
                                                <Grid
                                                  //justifyContent="space-between"
                                                  //container
                                                  className={classes.myEvent}
                                                  ref={provided.innerRef}
                                                  {...provided.dragHandleProps}
                                                  {...provided.draggableProps}
                                                  style={{
                                                    border: `2px solid ${item.role_bgcolor}`,
                                                    backgroundColor:
                                                      item.isPublished
                                                        ? item.role_bgcolor
                                                        : myBg,
                                                    ...provided.draggableProps
                                                      .style,
                                                  }}
                                                >
                                                  <Grid
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      flexDirection: "row",
                                                    }}
                                                  >
                                                    <Grid item></Grid>

                                                    <Grid item>
                                                      {column.date ===
                                                      moment(new Date()).format(
                                                        "YYYY-MM-DD"
                                                      ) ? (
                                                        <Typography
                                                          className={
                                                            classes.shiftTitle
                                                          }
                                                          // onClick={() => { editShiftsModalOpen(item) }}
                                                        >
                                                          {shiftTitle}
                                                        </Typography>
                                                      ) : (
                                                        <Typography
                                                          className={
                                                            classes.shiftTitle
                                                          }
                                                        >
                                                          {shiftTitle}
                                                        </Typography>
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                  {column.date ===
                                                  moment(new Date()).format(
                                                    "YYYY-MM-DD"
                                                  ) ? (
                                                    <Typography
                                                      className={
                                                        classes.shiftTitle
                                                      }
                                                      style={{
                                                        textAlign: "center",
                                                        color: "black",
                                                        marginInline: "auto",
                                                      }}
                                                      // onClick={() => { editShiftsModalOpen(item) }}
                                                    >
                                                      {item.statusLabel}
                                                    </Typography>
                                                  ) : (
                                                    <Typography
                                                      className={
                                                        classes.shiftTitle
                                                      }
                                                      style={{
                                                        textAlign: "center",
                                                        color: "black",
                                                        marginInline: "auto",
                                                      }}
                                                    >
                                                      {item.statusLabel}
                                                    </Typography>
                                                  )}
                                                </Grid>
                                              ) : item.type ===
                                                "availability" ? (
                                                <></>
                                              ) : (
                                                <Grid
                                                  // onClick={() => {
                                                  //   permissionsData.scheduler_schedule_edit_events &&
                                                  //     editEventsModalOpen(
                                                  //       item
                                                  //     );
                                                  // }}
                                                  justifyContent="center"
                                                  container
                                                  className={classes.myEvent}
                                                  ref={provided.innerRef}
                                                  {...provided.dragHandleProps}
                                                  {...provided.draggableProps}
                                                  style={{
                                                    cursor: "pointer",
                                                    padding: "12.5px 0px",

                                                    border: `2px solid rgb(245, 232, 245)`,
                                                    backgroundColor:
                                                      "rgb(245, 232, 245)",
                                                    ...provided.draggableProps
                                                      .style,
                                                  }}
                                                >
                                                  <Typography
                                                    style={{
                                                      color: "#000000",
                                                    }}
                                                    className={
                                                      classes.shiftTitle
                                                    }
                                                  >
                                                    {item.title}
                                                  </Typography>
                                                  {/* <br/>
                                                    <Typography
                                                      style={{
                                                        color: "#000000",
                                                      }}
                                                      className={
                                                        classes.shiftTitle
                                                      }
                                                    >
                                                      {item.description}
                                                    </Typography> */}
                                                </Grid>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                  </TableCell>
                                )}
                              </Droppable>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  {this.props.toolbarSettings.people_forecast ||
                  this.props.toolbarSettings.wages_forecast ||
                  this.props.toolbarSettings.hours_forecast ? (
                    <TableRow>
                      <TableCell
                        style={{ padding: "10px", background: "#fcfcfc" }}
                        className={classes.eventUserCell}
                      >
                        {/* {this.props.toolbarSettings.wages_forecast ? (
                  <Typography style={{ display: "flex" }} variant="h6">
                    <b style={{ marginRight: "auto" }}>EST WAGES</b>
                    <b>${parseFloat(total_est_wages).toFixed(2)}</b>
                  </Typography>
                ) : (
                  ""
                )} */}

                        {this.props.toolbarSettings.hours_forecast ? (
                          <Typography style={{ display: "flex" }} variant="h6">
                            <b style={{ marginRight: "auto" }}>SCH HOURS</b>{" "}
                            <b>
                              {parseFloat(total_sch_hours).toFixed(2)}&nbsp;Hrs
                            </b>
                          </Typography>
                        ) : (
                          ""
                        )}
                        {this.props.toolbarSettings.break_forecast ? (
                          <Typography style={{ display: "flex" }} variant="h6">
                            <b style={{ marginRight: "auto" }}>
                              BREAK
                              {/* FORECAST */}
                            </b>{" "}
                            <b>
                              {parseFloat(break_forecast_hrs).toFixed(2)}
                              &nbsp;Hrs
                            </b>
                          </Typography>
                        ) : (
                          ""
                        )}
                      </TableCell>

                      {columns.map((column) => {
                        var totalShifts = [];
                        var total_scheduled_hrs = 0;
                        var total_scheduled_wage = 0;
                        var break_forecast = 0.0;
                        var open_shifts = 0;
                        var count = 0;
                        usersData.map((users) => {
                          users.myShifts.map((myRows) => {
                            var currentDate = moment
                              .utc(myRows?.currentDate)
                              .format("YYYY-MM-DD");
                            if (
                              column.date === currentDate &&
                              myRows?.type === "shifts"
                            ) {
                              //totalShifts = totalShifts + 1
                              break_forecast = myRows?.break_forecast_hrs
                                ? myRows?.break_forecast_hrs
                                : 10;
                              open_shifts = myRows?.open_shifts
                                ? myRows?.open_shifts
                                : 0;
                              var total_sc_hrs = parseFloat(
                                myRows?.total_scheduled_hrs
                                  ? myRows?.total_scheduled_hrs
                                  : 0
                              );
                              total_scheduled_hrs =
                                parseFloat(total_scheduled_hrs) +
                                parseFloat(total_sc_hrs);
                              total_scheduled_wage =
                                parseFloat(total_scheduled_wage) +
                                parseFloat(myRows?.total_scheduled_wage);
                              if (users.myShifts.length > 0) {
                                totalShifts.push(users._id);
                              }
                              const toFindDuplicates = (totalShifts) =>
                                totalShifts.filter(
                                  (item, index) =>
                                    totalShifts.indexOf(item) === index
                                );
                              totalShifts = toFindDuplicates(totalShifts);
                            }
                          });
                        });
                        return (
                          <TableCell
                            style={{
                              verticalAlign: "middle",
                              background: "#fcfcfc",
                            }}
                            className={classes.eventCell}
                          >
                            {this.props.toolbarSettings.people_forecast ? (
                              <UserIcon
                                color="primary"
                                style={{ float: "left", margin: "5px 2px" }}
                              />
                            ) : (
                              ""
                            )}
                            &nbsp;&nbsp;
                            {this.props.toolbarSettings.people_forecast ? (
                              <Typography
                                style={{ float: "left", margin: "7px 2px" }}
                                variant="h6"
                              >
                                {totalShifts.length}
                              </Typography>
                            ) : (
                              ""
                            )}
                            <Typography
                              style={{
                                float: "right",
                                display: "grid",
                                marginRight: "5px",
                                textAlign: "right",
                              }}
                              variant="h6"
                            >
                              {this.props.toolbarSettings.hours_forecast ? (
                                <b
                                  style={{
                                    lineHeight:
                                      this.props.toolbarSettings
                                        .wages_forecast &&
                                      this.props.toolbarSettings.hours_forecast
                                        ? "1.2rem"
                                        : "2rem",
                                  }}
                                >
                                  {parseFloat(total_scheduled_hrs).toFixed(2)}
                                  &nbsp;Hrs
                                </b>
                              ) : (
                                ""
                              )}

                              {/* {this.props.toolbarSettings.break_forecast ? (
                        <b
                          style={{
                            lineHeight: this.props.toolbarSettings
                              .break_forecast
                              ? "1.2rem"
                              : "2rem",
                          }}
                        >
                          ${break_forecast}
                        </b>
                      ) : (
                        ""
                      )} */}
                              {/* {this.props.toolbarSettings.open_shifts ?
                        <b style={{
                          lineHeight: this.props.toolbarSettings.open_shifts
                            ? "1.2rem" : "2rem"
                        }}>
                          ${open_shifts}
                        </b>
                        : ""} */}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ) : (
                    ""
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DragDropContext>

        {/* <Table className={classes.table} size="medium"> */}
        {/* 
          <TableRow>
            <TableCell
              style={{ padding: "10px", background: "#fcfcfc" }}
              className={classes.eventUserCell}
            >
              <Typography variant="h5">Weather Forecast</Typography>
            </TableCell>
            {this.props.weatherData.map((myWeather, index) => {
              if (index !== 0)
                return (
                  <TableCell
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      background: "#fcfcfc",
                    }}
                    className={classes.eventCell}
                  >
                    <Typography
                      style={{ fontSize: "12px" }}
                      display="inline"
                      variant="h6"
                    >
                      {parseInt(myWeather.temp.max - 273.15)}
                      <sup>o</sup>C
                    </Typography>
                    {index % 2 ? (
                      <CloudIcon
                        color="primary"
                        style={{ margin: "-8px 20px" }}
                      />
                    ) : (
                      <WbSunnyIcon
                        color="primary"
                        style={{ margin: "-8px 20px" }}
                      />
                    )}
                    <Typography
                      style={{ fontSize: "12px" }}
                      display="inline"
                      variant="h6"
                    >
                      {parseInt(myWeather.temp.min - 273.15)}
                      <sup>o</sup>C
                    </Typography>
                  </TableCell>
                );
            })}
          </TableRow> */}
        {/* </Table> */}
        {/* weather end */}
      </Grid>
    );
  }
}

export default withStyles(styles)(Dashboard);
