//MyCenterModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Loader } from "../../../components";
import {
  Avatar,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Slide,
  DialogActions,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { utcToLocal } from "../../../utilities";
import { schedulerColor, FILE_URL } from "../../../config";
import { timecard_next, timecard_prev } from "../../../icons";
import moment from "moment";
const styles = (theme) => ({
  dialog: {
    borderRadius: 0,
  },
  centerdialog: {
    // width: "800px",
    // maxWidth: "800px",
    // height: "450px"
  },
  previousIcon: {
    // backgroundColor: '#ffffff',
    padding: "1rem",
    top: "45%",
    left: "1rem",
    position: "fixed",
    "&:hover": {
      // backgroundColor: '#ffffff',
    },
  },
  nextIcon: {
    // backgroundColor: '#ffffff',
    padding: "1rem",
    top: "45%",
    right: "1rem",
    position: "fixed",
    // marginLeft:'1rem',
    "&:hover": {
      // backgroundColor: '#ffffff',
    },
  },
  buttonSignup: {
    boxShadow: "none",
    fontWeight: 400,
    color: "#ffffff",
    backgroundColor: "green",
    borderRadius: 0,
    border: "1px solid green",
    padding: "5px 22px",
  },
  buttonLogin: {
    boxShadow: "none",
    fontWeight: 400,
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    border: "1px solid red",
    padding: "5px 22px",
  },
  DialogTitle: {
    padding: "10px 20px",
  },
  avatarText: {
    float: "left",
    textTransform: "uppercase",
    height: "45px",
    width: "45px",
    fontWeight: 800,
    fontSize: "15px",
    backgroundColor: schedulerColor.main,
    color: "#ffffff",
  },
  myTitle: {
    color: schedulerColor.main,
    fontSize: "14px",
    marginLeft: "2px",
    paddingTop: "5px",
    lineHeight: "20px",
    float: "left",
    fontWeight: 600,
  },

  closeIcon: {
    color: schedulerColor.main,
    cursor: "pointer",
    float: "right",
  },
  dialogcontent: {
    minHeight: "510px",
    maxWidth: "2000px",
    backgroundColor: "#F7F8FC",
    padding: "0px 15px !important",
    overflowY: "auto",
  },
  saveButton: {
    color: "#ffffff",
    padding: "10px 22px",
  },
  closeButton: {
    color: "#ffffff",
    padding: "10px 22px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class MyCenterModal extends Component {
  descriptionElementRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const { current: descriptionElement } = this.descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }

  render() {
    const { classes, children, myItem, timecardLoading, permissionsData } =
      this.props;
    var date = moment(utcToLocal(new Date())).format("YYYY-MM-DD");
    return (
      <Dialog
      maxWidth="lg"
      open={this.props.open}
      onClose={this.props.onClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          minWidth: '800px', // Ensures the modal stays wide enough when the loader is displayed
          maxWidth: '2000px', // Optional: to limit the max width
        },
      }}
    >
      {timecardLoading === true ? (
        <Loader />
      ) : (
        <DialogContent
          className={classes.dialogcontent}
          ref={this.descriptionElementRef}
          dividers
          tabIndex={-1}
        >
          {this.props.myIndex ? (
            <IconButton
              className={classes.previousIcon}
              onClick={this.props.previousTimecardClick}
            >
              <Avatar
                style={{ height: "75px", width: "75px" }}
                src={timecard_prev}
              />
            </IconButton>
          ) : (
            ""
          )}
          {this.props.myArray.length === this.props.myIndex + 1 ? (
            ""
          ) : (
            <IconButton
              className={classes.nextIcon}
              onClick={this.props.nextTimecardClick}
            >
              <Avatar
                style={{ height: "75px", width: "75px" }}
                src={timecard_next}
              />
            </IconButton>
          )}
          {children}
        </DialogContent>
      )}
     <DialogActions style={{ padding: "12px 12px" }}>
          <Grid className={classes.root2} container spacing={2}>
         
            <Grid item sm={8} xs={12}>
            {timecardLoading === false ?
              <Grid container spacing={0} className={classes.shadow}>
                <Grid item sm={6} xs={12}>
                  <Grid container spacing={0}>
                    <Grid item sm={3} xs={12} style={{ whiteSpace: "nowrap" }}>
                      <Typography className={classes.myTitle} variant="h4">
                        Approved Hours
                      </Typography>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                      <Typography
                        className={classes.myTitle}
                        style={{ color: "#000" }}
                        variant="h4"
                      >
                        :&nbsp;&nbsp;{myItem.total_manual_hrs}&nbsp; Hrs
                      </Typography>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <Typography className={classes.myTitle} variant="h4">
                        Sch Hours
                      </Typography>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                      <Typography
                        className={classes.myTitle}
                        style={{ color: "#000" }}
                        variant="h4"
                      >
                        :&nbsp;&nbsp;{myItem.total_scheduled_hrs}&nbsp; Hrs
                      </Typography>
                    </Grid>

                    <Grid item sm={3} xs={12} style={{ whiteSpace: "nowrap" }}>
                      <Typography className={classes.myTitle} variant="h4">
                        Paid Hours
                      </Typography>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                      <Typography
                        className={classes.myTitle}
                        style={{ color: "#000" }}
                        variant="h4"
                      >
                        :&nbsp;&nbsp;{myItem.total_paid_hrs}&nbsp; Hrs
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Grid container spacing={0}>
                    <Grid item sm={6} xs={12}>
                      <Typography className={classes.myTitle} variant="h4">
                        Payroll Template Name
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        className={classes.myTitle}
                        style={{ color: "#484C4D" }}
                        variant="h4"
                      >
                        :&nbsp;&nbsp;{myItem.payroll_name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      xs={12}
                      style={{ justifyContent: "right", textAlign: "right" }}
                    >
                      <Typography className={classes.myTitle} variant="h4">
                        Payroll Rule
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        className={classes.myTitle}
                        style={{ color: "#484C4D" }}
                        variant="h4"
                      >
                        :&nbsp;{myItem.payroll_rule}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>:""}
            </Grid>
         
          </Grid>
        </DialogActions>
    </Dialog>
    
    );
  }
}

export default withStyles(styles)(MyCenterModal);
