import {
  sc_image_dashboard,
  sc_image_dashboard_active,
  sc_image_scheduler,
  sc_image_scheduler_active,
  sc_image_myavailability,
  sc_image_myavailability_active,
  sc_image_timeoffrequest,
  sc_image_timeoffrequest_active,
  sc_image_tradeshift,
  sc_image_tradeshift_active,
  sc_image_req,
  sc_image_req_active,
  sc_image_covershift,
  sc_image_covershift_active,
  sc_image_timesheets,
  sc_image_timesheets_active,
  sc_image_pay_summary,
  sc_image_pay_summary_active,
  sc_image_settings_scheduling,
  sc_image_settings_scheduling_active,
} from "../icons";
// import TimelineIcon from "@mui/icons-material/Timeline";

export const SchedulerRoutes = [
  {
    label: "Dashboard",
    path: "/scheduler/dashboard",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
  },
  {
    label: "Scheduler",
    path: "/scheduler/scheduler/my",
    additionalPaths: ["/scheduler/scheduler/team"],
    image: sc_image_scheduler,
    active_image: sc_image_scheduler_active,
  },
  {
    type: "divider",
  },
  {
    label: "Availability",
    path: "/scheduler/availability/my",
    additionalPaths: ["/scheduler/availability/team"],
    image: sc_image_myavailability,
    active_image: sc_image_myavailability_active,
  },
  {
    type: "divider",
  },
  {
    // asad hide
    path: "/",
    label: "Request",
    image: sc_image_req,
    active_image: sc_image_req_active,
    childData: [
      {
        label: "Trade shift",
        path: "/scheduler/tradeshift/my",
        additionalPaths: ["/scheduler/tradeshift/team"],
        image: sc_image_tradeshift,
        active_image: sc_image_tradeshift_active,
        count: "scheduler_trade_shifts",
      },
      {
        label: "Cover shift",
        path: "/scheduler/covershift/my",
        additionalPaths: ["/scheduler/covershift/team"],
        image: sc_image_covershift,
        active_image: sc_image_covershift_active,
      },
      {
        label: "Time off",
        path: "/scheduler/timeoffrequest/my",
        additionalPaths: ["/scheduler/timeoffrequest/team"],
        image: sc_image_timeoffrequest,
        active_image: sc_image_timeoffrequest_active,
        count: "scheduler_timeoff_requests",
      },
      {
        label: "Correction",
        path: "/scheduler/correction_request/my",
        additionalPaths: ["/scheduler/correction_request/team"],
        image: sc_image_pay_summary,
        active_image: sc_image_pay_summary_active,
      },
    ],
  },
  {
    type: "divider",
  },
  {
    label: "Timesheets",
    path: "/scheduler/timesheets",
    image: sc_image_timesheets,
    active_image: sc_image_timesheets_active,
  },
  {
    label: "Pay run",
    path: "/scheduler/pay_summary",
    image: sc_image_pay_summary,
    active_image: sc_image_pay_summary_active,
  },
  {
    type: "divider",
  },
  {
    path: "/",
    label: "Settings",
    childData: [
      {
        label: "Time Tracking",
        path: "/scheduler/settings/time_tracking",
        image: sc_image_pay_summary,
        active_image: sc_image_pay_summary_active,
      },
      {
        label: "Scheduling",
        path: "/scheduler/settings/scheduling",
        image: sc_image_settings_scheduling,
        active_image: sc_image_settings_scheduling_active,
      },
      {
        label: "Payroll",
        path: "/scheduler/settings/payrollNew",
        image: sc_image_pay_summary,
        active_image: sc_image_pay_summary_active,
      },
      {
        label: "Departments",
        path: "/scheduler/settings/roles",
        image: sc_image_pay_summary,
        active_image: sc_image_pay_summary_active,
      },
      {
        label: "Payroll Management",
        path: "/scheduler/settings/payrollmanagement",
        image: sc_image_pay_summary,
        active_image: sc_image_pay_summary_active,
      },
    ],
  },
];
