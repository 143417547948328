import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { schedulerColor } from '../../../config'
import { MyTextArea, MyAutocomplete, MyIncreDecre, BasicTimePicker } from '../../../components'

import { addDays } from 'date-fns';
import moment from "moment";
import {
  TextField,
  Grid,
  Avatar,
  Typography,
  IconButton, Box
}
  from '@material-ui/core';
import dayjs from 'dayjs';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  avatarDay: {
    padding: "2px",
    cursor: "pointer",
    float: 'left',
    textTransform: 'uppercase',
    height: '30px',
    width: '30px',
    fontWeight: 800,
    fontSize: '10px',
    color: '#ffffff'
  },
  avatarText: {
    float: 'left',
    textTransform: 'uppercase',
    height: '35px',
    width: '35px',
    fontWeight: 800,
    fontSize: '15px',
    backgroundColor: '#DFF2F7',
    color: '#8A5EA6'
  },
  userName: {
    color: schedulerColor.main,
    fontSize: '14px',
    fontWeight: 600,
    marginLeft: '3px',
    lineHeight: '2.1rem',
    display: "inline",
    float: 'left',
    cursor: 'move'
  },
});


class AddEditShifts extends Component {
  render() {
    const { classes,
      myItem,
      columns,
      rolesData,
      errors,
      errorsHelper,
      timeArray,
      roleSelected,
      startTimeSelected,
      endTimeSelected,
      addMyShift,
      timezone
    } = this.props;

    // moment.tz.setDefault(timezone);
    // var date = new Date(moment(this.props.myItem.currentDate[0]).format("YYYY-MM-DD"));
    // date = addDays(date, 1);
    var date = new Date(this.props.myItem.currentDate[0]);
    date.setDate(date.getDate() + 1)
    // date = date.setDate(date.getDate() + 1)
    var start_time = moment(this.props.myItem.currentDate[0]).format("YYYY-MM-DD ") + moment(this.props.startTimeSelected?.name, "hh:mm A").format(" HH:mm:ss")
    var end_time = moment(this.props.nextDay ? date : this.props.myItem.currentDate[0]).format("YYYY-MM-DD ") + moment(this.props.endTimeSelected?.name, "hh:mm A").format(" HH:mm:ss")
    var duration = moment.duration(moment(end_time).diff(moment(start_time)))
    var minites = duration.asMinutes()
    var DataOne = "0" + " hour and " + "0" + " minute."
    if (this.props.startTimeSelected?.name !== undefined && this.props.endTimeSelected?.name !== undefined) {
      var hours = (minites / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      DataOne = rhours + " hour and " + rminutes + " minute."
    }


    return (
      <Grid
        container
        spacing={2}
        className={classes.root}
      >
        <Grid
          item
          sm={12}
          xs={12}
        >
          {columns.map((column) => {
            return (
              myItem.currentDate?.includes(column.date) ?
                <IconButton
                  disabled={column.isPast || column.isCurrent}
                  style={{ opacity: column.isPast || column.isCurrent ? .5 : 1, marginLeft: '10px', padding: '0px' }}
                  onClick={() => { this.props.handleChangeDates(column.date, 0) }}
                >
                  <Avatar
                    style={{
                      backgroundColor: schedulerColor.main,
                      color: "#ffffff"
                    }}
                    className={classes.avatarDay} >
                    {column.getDate}<br />
                    {column.dayName}
                  </Avatar>
                </IconButton>
                :
                <IconButton
                  disabled={column.isPast || column.isCurrent}
                  style={{ opacity: column.isPast || column.isCurrent ? .5 : 1, marginLeft: '10px', padding: '0px' }}
                  onClick={() => { this.props.handleChangeDates(column.date, 1) }}
                >
                  <Avatar
                    style={{
                      backgroundColor: "silver",
                      color: "#000000"
                    }}
                    className={classes.avatarDay} >
                    {column.getDate}<br />
                    {column.dayName}
                  </Avatar>
                </IconButton>
            )
          })}
          {errors.currentDate ?
            <Typography
              style={{ color: "red", fontSize: "11px" }}
              variant="body1"
              gutterBottom
            >
              {errorsHelper.currentDate}
            </Typography> : ""}
        </Grid>
        <Grid
          item
          sm={6}
          // sm={12}
          xs={12}
        >
          <MyAutocomplete
            label="Start Time"
            name="startTime"
            error={errors.startTime}
            helperText={errors.startTime ? errorsHelper.startTime : ""}
            value={startTimeSelected}
            options={timeArray}
            getOptionLabel={(item) => item.name}
            renderInput={(params) =>
              <TextField {...params}
                variant="outlined"
              />}
            onFocus={this.props.handleFocus}
            onChange={this.props.handleChangeStartTime}
          />
  
      
        </Grid>

        <Grid
          item
          sm={6}
          // sm={12}
          xs={12}
        >
          <MyAutocomplete
            label="End Time"
            name="endTime"
            error={errors.endTime}
            helperText={errors.endTime ? errorsHelper.endTime : ""}
            value={endTimeSelected}
            options={timeArray}
            getOptionLabel={(item) => item.name}
            renderInput={(params) =>
              <TextField {...params}
                variant="outlined"
              />}
            onFocus={this.props.handleFocus}
            onChange={this.props.handleChangeEndTime}
          />
          <Typography style={{ color: "red" }}>{this.props.nextDay ? "Next Day" : ""}</Typography>
        
        </Grid>

        <Grid
          item
          sm={12}
          xs={12}
        >

          <Typography
            style={{ color: "Black", fontSize: "16px", fontWeight: 800 }}
            variant="body1"
            gutterBottom
          >
            Duration: &nbsp; &nbsp;{DataOne}
          </Typography>
        </Grid>

        <Grid
          item
          sm={12}
          xs={12}
        >
          <MyAutocomplete
            label="Department"
            name="role_id"
            error={errors.role_id}
            helperText={errors.role_id ? errorsHelper.role_id : ""}
            value={roleSelected}
            options={rolesData}
            getOptionLabel={(item) => item.role_name}
            renderInput={(params) =>
              <TextField {...params}
                variant="outlined"
              />}
            onFocus={this.props.handleFocus}
            onChange={this.props.handleChangeRoles}
          />
        </Grid>


        <Grid item sm={12} xs={12}>
          <MyTextArea
            name="shift_notes"
            label="Notes"
            onChange={this.props.handleChangeItem}
            onFocus={this.props.handleFocus}
            value={myItem.shift_notes}
            type="text"
            error={errors.shift_notes}
            helperText={errors.shift_notes ? errorsHelper.shift_notes : ""}
            placeholder="Leave a note for your employee, lie the address of a job site , and they'll see it when they clock in."
            rows={5}
          />
        </Grid>

      </Grid>
    )
  }
}

export default withStyles(styles)(AddEditShifts);
