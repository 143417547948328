import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { LoadingData, NoPermission } from "../../../components";
// import BarDemo from '../Dashboard/BarDemo';
import { schedulerColor } from "../../../config";
import { connect } from "react-redux";
// import filterArray from '../../../utils/feedback_filter_data';
import { Grid, Button, Typography } from "@material-ui/core";
import moment from "moment";
import SwitchSelector from "react-switch-selector";
import MyAvailability from "./MyAvailability";
import TeamAvailability from "./TeamAvailability";
import { history } from "../../../history";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  tabButton: {
    border: `1px solid ${schedulerColor.main}`,
    textTransform: "capitalize",
    padding: "10px",
    fontWeight: 500,
    fontSize: "14px",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#fcfcfc",
    },
  },
  avatar: {
    height: "30px",
    width: "30px",
    fontWeight: "600",
    fontSize: "15px",
    backgroundColor: schedulerColor.main,
    color: "white",
  },
  schtabletrhead: {
    backgroundImage: "linear-gradient(0deg, #AA076B, #9F0D9C)",
  },
});

// export function fetchAvailabilityData() {
//     window.AvailabilityMain();
// }

class AvailabilityMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addEditModal: false,
      report_name: "",
      errors: {},
      errorsHelper: {},
      selectedCategoryIds: [],
      selectedLocationIds: [],
      campaignLocations: [],
      campaignCategory: [],
      myItem: {},
      dataPass: 0,
      dataPass1: 0,
      buttonsList: [
        {
          status: 0,
          title: "My",
        },
        {
          status: 1,
          title: "Team",
        },
      ],
      option: [
        {
          label: "My",
          value: "my",
          selectedBackgroundColor: schedulerColor.main,
          innerHeight: 180,
        },
        {
          label: "Team",
          value: "team",
          selectedBackgroundColor: schedulerColor.main,
          innerHeight: 180,
        },
      ],
      activeIndex: "",
      order: "asc",
      orderBy: "_id",
    };
    document.title = "Dashboard";
  }

  createSortHandler = (property) => (event) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  setActiveIndex = (activeIndex) => {
    this.setState({ activeIndex }, () => {
      if (activeIndex === "team") {
        var url = `/scheduler/availability/team`;
        history.push(url);
      } else {
        var url = `/scheduler/availability/my`;
        history.push(url);
      }
    });
  };

  componentDidMount = () => {
    if (window.location.pathname.includes("/scheduler/availability/team")) {
      this.setState({
        activeIndex: "team",
      });
    } else {
      this.setState({
        activeIndex: "my",
      });
    }
  };

  render() {
    const { classes, rowsPerPage, pageNo, timezone, permissionsData } =
      this.props;

    const { buttonsList, activeIndex } = this.state;
    const initialSelectedIndex = this.state.option.findIndex(
      ({ value }) =>
        value ===
        (window.location.pathname.includes("/scheduler/availability/my")
          ? "my"
          : "team")
    );
    return (
      // permissionsData.scheduler_team_availability  ?
      <Grid
        container
        spacing={4}
        style={{ padding: "55px" }}
        className={classes.root}
      >
        <Typography
          style={{ color: "#AA076B", fontWeight: 700, fontSize: "26px" }}
        >
          Availability
        </Typography>
        <Grid
          style={{ margin: "auto" }}
          item
          xl={6}
          md={6}
          lg={8}
          sm={8}
          xs={12}
        >
          <Grid container spacing={0}>
            {/* {buttonsList.map((label, index) => {
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        xl={3}
                                        md={3}
                                        sm={3}
                                        xs={3}
                                    >
                                        <Button
                                            style={{
                                                borderRadius: 28,
                                                color: activeIndex === label.status ? "#ffffff" : schedulerColor.main,
                                                backgroundColor: activeIndex === label.status ? schedulerColor.main : "#ffffff",
                                            }}
                                            className={classes.tabButton}
                                            fullWidth
                                            onClick={() => { this.setActiveIndex(label.status) }}
                                        >
                                            {label.title}
                                        </Button>
                                    </Grid>
                                )
                            })} */}
            <p style={{ height: "38px", width: "220px", margin: "auto" }}>
              <SwitchSelector
                onChange={this.setActiveIndex}
                options={this.state.option}
                initialSelectedIndex={initialSelectedIndex}
                //value={this.state.activeIndex}
                backgroundColor={"white"}
                border="1px solid rgba(0,0,0,0.12)"
                fontColor={"gray"}
                fontSize={16}
                wrapperBorderRadius={0}
                optionBorderRadius={0}
                className={classes.myswit}
              />
            </p>
          </Grid>
        </Grid>
        {activeIndex === "my" ? (
          <MyAvailability
            scheduler_lid={this.props.scheduler_lid}
            permissionsData={this.props.permissionsData}
            rowsPerPage={this.props.rowsPerPage}
            pageNo={this.props.pageNo}
            timezone={timezone}
          />
        ) : activeIndex === "team" ? (
          <TeamAvailability
            scheduler_lid={this.props.scheduler_lid}
            permissionsData={this.props.permissionsData}
            rowsPerPage={this.props.rowsPerPage}
            pageNo={this.props.pageNo}
            timezone={timezone}
          />
        ) : (
          ""
        )}
      </Grid>

      // <Grid
      //     container
      //     spacing={2}
      //     className={classes.root}
      // >
      //     <Grid item md={12} className={classes.buttonMain}>
      //         <NoPermission />
      //     </Grid>
      // </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoadingDashboard: state.eLearningDashboard.isLoadingDashboard,
});

const ConnectWith = connect(mapStateToProps, null)(AvailabilityMain);
export default withStyles(styles)(ConnectWith);
