import { HEADER_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from "axios";

export const rolesServices = {
  cloneRoles,
  fetchData,
  fetchUsersByRole,
  addData,
  editData,
  deleteData,
  userPermissions,
  getroleDetails
};

// function fetchData() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/role/mongo`, HEADER_TOKEN)
//     .then((response) => response);
// }

// function fetchData(page) {
//   return axios
//     .get(
//       `${MoNGO_DB_API_URL}/role/mongo/paginated?pageNumber=${page}&nPerPage=10`,
//       HEADER_TOKEN
//     )page
//     .then((response) => response);
// }

function fetchData(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/hr/roles?page=${data.pageNumber}&limit=${process.env.REACT_APP_LIMIT}`
  if(data.name){
    url=`${url}&q=${data.name}`
  }
  return axios
    .get(url, HEADER_TOKEN)
    .then((response) => response);
}
// function fetchData(page) {
//   return axios
//     .get(
//       `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/role/paginated?pageNumber=${page}&nPerPage=10`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }

// function fetchUsersByRole(_id) {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/role/users/${_id}`, HEADER_TOKEN)
//     .then((response) => response);
// }

function getroleDetails(_id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/roles/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
function fetchUsersByRole(_id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/roles/users/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function userPermissions() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/role/permissions`, HEADER_TOKEN)
//     .then((response) => response);
// }
function userPermissions() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/role/permissions`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function addData(data) {
//   return axios
//     .post(`${MoNGO_DB_API_URL}/role/mongo/v3/add`, { data }, HEADER_TOKEN)
//     .then((response) => response);
// }

function addData(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/roles`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function cloneRoles(data) {
//   return axios
//     .post(`${MoNGO_DB_API_URL}/role/mongo/Test/clone`, { data }, HEADER_TOKEN)
//     .then((response) => response);
// }
function cloneRoles(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/roles/clone`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function editData(data) {
//   return axios
//     .put(
//       `${MoNGO_DB_API_URL}/role/mongo/v3/edit/${data._id}`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }

function editData(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/roles/${data._id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function deleteData(_id) {
//   return axios
//     .delete(`${MoNGO_DB_API_URL}/role/${_id}`, HEADER_TOKEN)
//     .then((response) => response);
// }
function deleteData(_id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/roles/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
