import React, { Component } from "react";
import ScSidebarLink from "./ScSidebarLink";
import { SchedulerRoutes } from "../../routes/SchedulerRoutes";

export default class SchedulerSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newRoutes: SchedulerRoutes,
    };
  }

  handleToggleRoutes = (index, isOpen) => {
    var newRoutes = this.state.newRoutes.map((item, myIndex) => {
      var cloneItem = item;
      if (myIndex === index) {
        cloneItem["isOpen"] = isOpen ? false : true;
      }
      return cloneItem;
    });

    this.setState({
      newRoutes: newRoutes,
    });
  };

  render() {
    const { countData } = this.props;
    return (
      <div>
        {this.state.newRoutes.map((page, key) => {
          return (
            <ScSidebarLink
              handleToggleRoutes={() => {
                this.handleToggleRoutes(key, page.isOpen);
              }}
              clickLink={this.props.clickLink}
              key={key}
              countData={this.props.countData}
              location={this.props.location}
              isSidebarOpen={this.props.isSidebarOpen}
              {...page}
            />
          );
        })}
      </div>
    );
  }
}
