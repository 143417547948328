import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

import {
  FormControlLabel,
  Checkbox,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Avatar,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import moment from "moment";
import CalIcon from "@material-ui/icons/CalendarToday";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: "0px auto",
    height: "75px",
    width: "75px",
    backgroundColor: "#AA076B",
  },
  dialogConfirm: {
    backgroundColor: "#c3cbbf87",
  },
  dialogContentConfirm: {
    "&:last-child": {
      paddingTop: "3rem",
    },
    backgroundColor: "#fcfcfc",
    padding: "3rem 2rem",
  },
}));

export default function AddMyAvailability(props) {
  const classes = useStyles();
  const [effectiveDate, seteffectiveDate] = React.useState("");
  const [dropdownData, setdropdownData] = React.useState([]);

  const getDaysArrayByMonth = (newAllDropdown) => {
    var daysInMonth = moment().daysInMonth();
    var today = new Date();
    const currentDay = today.getDate();

    var mydate = "";
    var myflag = true;

    for (let index = currentDay; index <= daysInMonth; index++) {
      var current = moment().date(index);
      var convertDate = current.format("YYYY-MM-DD");
      var checkDate = newAllDropdown.indexOf(convertDate) > -1;

      if (checkDate === false && myflag === true) {
        mydate = convertDate;
        myflag = false;
        seteffectiveDate(convertDate);
      }
    }
  };

  const disableWeekends = (date) => {
    var myDates = [];
    props.dropdownData.map((item) => {
      myDates.push(moment(item.effective_date).format("YYYY-MM-DD"));
    });
    const currentDay3 = ("0" + date.getDate()).slice(-2);
    const currentDay2 = ("0" + (date.getMonth() + 1)).slice(-2);
    const currentDay1 = date.getFullYear();
    var convertDate = currentDay1 + "-" + currentDay2 + "-" + currentDay3;
    var checkDate = myDates.indexOf(convertDate) > -1;
    return checkDate;
  };

  return (
    <Dialog
      className={classes.dialogConfirm}
      maxWidth="sm"
      fullWidth
      onClose={props.newModalClose}
      open={props.newModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.dialogContentConfirm} dividers>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <Avatar className={classes.avatar} color="primary">
              <CalIcon
                style={{ color: "white", height: "40px", width: "40px" }}
              />
            </Avatar>
          </Grid>

          <Grid item md={12} xs={12}>
            <Typography
              style={{ textAlign: "center" }}
              color="textSecondary"
              variant="h5"
            >
              When will your new availability take effect?
            </Typography>
          </Grid>

          <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                autoOk
                //shouldDisableDate={disableWeekends}
                disableToolbar
                disablePast
                size="small"
                variant="inline"
                inputVariant="outlined"
                name="effectiveDate"
                format="dd-MMM-yyyy"
                value={props.effective_date}
                onChange={props.handleEffectiveDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {/* 
          <Grid
            item
            md={12}
            xs={12}
            style={{ textAlign: "center" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.isChecked}
                  onChange={props.handleCheckBox}
                  color="primary"
                />
              }
              label="Copy availabilities from current schedule"
            />
          </Grid> */}
        </Grid>

        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            style={{ border: "1px solid #AA076B", color: "#AA076B" }}
            onClick={props.newModalClose}
          >
            Cancel
          </Button>

          <Button
            variant="outlined"
            onClick={props.newAvailability}
            style={{ border: "1px solid #AA076B", color: "#AA076B" }}
            autoFocus
          >
            Create
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
