import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import ArrowDropDownCircleRoundedIcon from "@mui/icons-material/ArrowDropDownCircleRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import CancelIcon from "@mui/icons-material/Cancel";
import {
  getScheduleData,
  updateScheduleData,
} from "../Settings/modules/actions";
import { connect } from "react-redux";
import { schedulerColor } from "../../../config";
// import {
//   alert,
//   localTimeToUtc,
//   validationIsEmpty,
//   sliderTimeReturn,
// } from "../../../utilities";
import moment from "moment";
// import Backdrop from "../../../components/Backdrop";
// import Modal from "../../../components/Modal";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import TimeZone from "../Settings/Timezone";
// import { history } from "../../../history";
// import { settingsServices } from "../Settings/modules/services";
// import EditIcon from "@mui/icons-material/Edit";
// import SaveSection from "../Settings/SaveSection";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import { StickyContainer, Sticky } from "react-sticky";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { utcToTimeZone } from "../../../utilities/utcToTimeZone";
import {
  // CardContent,
  // MenuItem,
  Typography,
  // FormControl,
  Grid,
  // Card,
  // Checkbox,
  // FormControlLabel,
  // TextField,
  // OutlinedInput,
  // FormGroup,
  // Tooltip,
  // IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  // ButtonGroup,
  Button,
  // FormLabel,
  // RadioGroup,
  // Radio,
} from "@material-ui/core";
// import Switch from "@mui/material/Switch";
// import ClearModal from './ClearModal';
// import NewModal from './NewModal';
// import NotifyManager from './NotifyManager';
// import ViewBreakModal from "../Settings/ViewBreakModal";
// import AddAvailability from './AddAvailability'
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
// import TimePicker from 'rc-time-picker';
import "rc-time-picker/assets/index.css";
import "./news.css";
// import { teamAvailabilityService } from "./modules/services";
// import CollapsedTableTeamAvailability from "./CollapsedTableTeamAvailability";

const styles = (theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.5em",
      display: "none",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#858585",
    },
  },
  root: {
    padding: theme.spacing(2),
  },
  HeadCell: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    color: "#232056",
  },
  listCell1: {
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
  },
  listCell2: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "20%",
    },
  },
  listCell3: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
  },
  listCell6: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
  },
  listCell5: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
  },
  listCell8: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "20%",
    },
  },
  listCell9: {
    width: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
  },
  // listCell1: {
  //   width: "15.5rem",
  //   // padding:'8px 4px'
  // },
  // listCell2: {
  //   width: "14rem",
  // },
  // listCell3: {
  //   width: "13rem",
  //   // padding:'8px 0'
  // },
  // listCell4: {
  //   width: "10rem",
  // },
  // listCell5: {
  //   width: "10rem",
  //   // padding:'8px 0'
  // },
  // listCell6: {
  //   width: "10rem",
  //   // padding:'8px 0'
  // },
  // listCell7: {
  //   width: "10rem",
  //   // padding:'8px 0'
  // },
  // listCell8: {
  //   width: "14rem",
  // },
  // listCell9: {
  //   width: "7.5rem",
  // },
  buttonMain: {
    textAlign: "right",
    fontWeight: 700,
  },
  button: {
    background: "#005F73",
    borderRadius: 8,
    color: "white",
    padding: 10,

    "&:hover": {
      background: "#005F73",
      borderRadius: 8,
    },
  },
  flex: {
    display: "flex",
    margin: "20px",
  },
  Heading: {
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "36px",
    fontWeight: 700,
  },
  HeadingValue: {
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "36px",
  },

  card: {
    //   margin: '15px 0px 0px 0px',
    borderRadius: 8,
    background: "#FFFFFF",
    //   boxShadow: '0 0 0.5em #E2E2E2',
    boxShadow: "0 12.5214px 16.9039px rgb(0 0 0 / 5%)",
  },
  scheduleCard: {
    background: "#FFFCFC",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    padding: " 16px 68px 0px 68px",
  },
  divWdth: {
    background: "#fff",
    border: "1px solid rgba(161, 161, 161, 0.5)",
    width: "100%",
    borderRadius: "10px",
    cursor: "pointer",
    height: "100%",
  },
  divWdths: {
    background: "#4D72F8",
    cursor: "pointer",
    color: "white",
    marginTop: 26,
    padding: 7,
    width: "122%",
    marginLeft: -8,
    height: 80,
  },
  time: {
    marginTop: 10,
    fontFamily: "Calibri",
  },
  editIcon: {
    color: "#4D72F8",
    marginLeft: 5,
    marginTop: -12,
  },
  schtabletrhead: {
    backgroundImage: "linear-gradient(45deg, #AA076B, #9F0D9C)",
  },
  viewSection: {
    fontWeight: 700,
    textDecoration: "underline",
    cursor: "pointer",
  },
  mainDivScale: {
    paddingTop: "1%",
    lineHeight: "1%",
    // border: "1px solid #F3F2F2",
    margin: "2%",
    width: "97%",
  },
  timeRang: {
    width: "100%",
    paddingLeft: "10%",
    marginTop: "-2%",
  },
  mainweek: {
    paddingLeft: "0%",
  },
  root: {
    padding: theme.spacing(2),
  },
  btnChild: {
    cursor: "pointer",
  },
});

export function fetchSCSettingsScheduling() {
  window.Scheduling.getScheduleData();
}

var format = "h:mm a";
var setTimePreStartNew = dayjs("2022-04-07");
var setTimePreEndNew = dayjs("2022-04-07");
var setTimeNew = dayjs("2022-04-07");
var setTimeEndNew = dayjs("2022-04-07");
class Scheduling extends Component {
  constructor(props) {
    super(props);
    window.Scheduling = this;
    this.state = {
      disable: true,
      isWeek: false,
      applyToAll: 0,
      workschedule: false,
      alertsAndNotification: false,
      // ScheduleDay: [
      //   { value: 1, label: "Monday" },
      //   { value: 2, label: "Tuesday" },
      //   { value: 3, label: "Wednesday" },
      //   { value: 4, label: "Thursday" },
      //   { value: 5, label: "Friday" },
      //   { value: 6, label: "Saturday" },
      //   { value: 0, label: "Sunday" },
      // ],
      emoji: 1,
      anchorEl: null,
      checked: true,
      open: false,
      openLeft: false,
      startDate: new Date(),
      startTime: moment().hour(0).minute(0),
      timezone: "",
      openModalSch: false,
      setTime: setTimeNew,
      setTimeEnd: setTimeEndNew,
      setTimePreStart: setTimePreStartNew,
      // setTimePreStartNew: '',
      setTimePreEnd: setTimePreEndNew,
      valueStartTime: moment(),
      valueStartTimeNew: moment(),
      valueEndtime: moment(),
      valueEndTimeNew: moment(),
      settings: {},
      valueTab: "0",
      data: {},
      allBreaks: [],
      timeArray: [],
      isWholeChecked: 0,
      addBreaksData: [],
      myavailabilityData: [],
      usersData: [],
      location_timezone: "",
      newLocationState: "",
      dayState: "",
      deleterowId: "",
      // break_start_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
      // break_end_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
      isBreakUpdate: 0,
      viewModalOpen: false,
      getApiResponse: [],
      weekDaysTimingsData: [
        {
          day: "Monday",
          schedule_start_day: 1,
          schedule_day_start_time: "",
          schedule_day_end_time: "",
        },
      ],
      weeksOrignal: [
        {
          day_id: 0,
          name: "Sunday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          day_id: 1,
          name: "Monday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          day_id: 2,
          name: "Tuesday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          day_id: 3,
          name: "Wednesday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          day_id: 4,
          name: "Thursday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          day_id: 5,
          name: "Friday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          day_id: 6,
          name: "Saturday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
      ],
      dropdownData: [],
    };
  }

  // toggleDisable = () => {
  //   if (this.state.breakss === true) {
  //     this.setState((prevState) => ({
  //       disable: !prevState.disable,
  //     }));
  //   }
  // };

  // handleChangeMainAvailability = (e) => {
  //   this.setState(
  //     {
  //       isLoading: true,
  //       av_id: e.target.value,
  //     },
  //     () => {
  //       history.push(`/scheduler/myavailabilitynew?av_id=${this.state.av_id}`);
  //     }
  //   );
  // };

  // handleClose = () => {
  //   this.setState({
  //     anchorEl: null,
  //   });
  // };

  // handleViewModalOpen = () => {
  //   this.setState({
  //     viewModalOpen: true,
  //   });
  // };

  // handleViewModalClose = () => {
  //   this.setState({
  //     viewModalOpen: false,
  //   });
  // };

  //   this.setState({ isWeek: !this.state.isWeek });
  //   if (e.target.checked) {
  //     var newRoutes = this.state.weekDaysTimingsData.map((item, myIndex) => {
  //       const openValue =
  //         this.state.weekDaysTimingsData[0]["schedule_day_start_time"];
  //       const closeValue =
  //         this.state.weekDaysTimingsData[0]["schedule_day_end_time"];
  //       var cloneItem = item;
  //       cloneItem["schedule_day_start_time"] = openValue;
  //       cloneItem["schedule_day_end_time"] = closeValue;
  //       return cloneItem;
  //     });
  //     this.setState({
  //       weekDaysTimingsData: newRoutes,
  //     });
  //   } else {
  //     var newRoutes = [...this.state.weekDaysTimingsData].map(
  //       (item, myIndex) => {
  //         const openValue =
  //           this.state.weekDaysTimingsData[0]["schedule_day_start_time"];
  //         const closeValue =
  //           this.state.weekDaysTimingsData[0]["schedule_day_end_time"];
  //         var cloneItem = item;
  //         cloneItem["schedule_day_start_time"] = "";
  //         cloneItem["schedule_day_end_time"] = "";
  //         if (myIndex === 0) {
  //           cloneItem["schedule_day_start_time"] = openValue;
  //           cloneItem["schedule_day_end_time"] = closeValue;
  //         }
  //         return cloneItem;
  //       }
  //     );
  //     this.setState({
  //       weekDaysTimingsData: newRoutes,
  //     });
  //   }
  // };

  // handleChangeWeekTiming = (e, isOpen, index) => {
  //   var newRoutes = this.state.weekDaysTimingsData.map((item, myIndex) => {
  //     var cloneItem = item;
  //     if (index === myIndex) {
  //       cloneItem[isOpen] = e.value;
  //     }
  //     return cloneItem;
  //   });
  //   this.setState({
  //     weekDaysTimingsData: newRoutes,
  //   });
  // };

  getScheduleData = () => {
    this.setState({
      isLoading: true,
    });
    var lid = this.props.scheduler_lid;
    this.props.getScheduleData(lid);
  };

  // handleChangeBreaksCheckbox = (e, index) => {
  //   var allBreaks = this.state.allBreaks;
  //   var state = e.target.name;
  //   var value = e.target.value;

  //   allBreaks[index][state] = allBreaks[index][state] ? 0 : 1;
  //   allBreaks[index].add_edit_status =
  //     allBreaks[index] && allBreaks[index]._id ? "edit" : "add";
  //   if (value) {
  //     this.setState((prevState) => ({
  //       allBreaks: JSON.parse(JSON.stringify(this.state.allBreaks)),
  //       isBreakUpdate: 1,
  //       data: {
  //         ...prevState.data,
  //         active_breakrule_id: value,
  //       },
  //       isBreakUpdate: 1,
  //     }));
  //   }
  // };

  // handleChangeBreaksValue = (e, index) => {
  //   var allBreaks = this.state.allBreaks;
  //   var state = e.target.name;
  //   var value = e.target.value;
  //   allBreaks[index][state] = value;
  //   allBreaks[index].add_edit_status =
  //     allBreaks[index] && allBreaks[index]._id ? "edit" : "add";
  //   this.setState({
  //     allBreaks: JSON.parse(JSON.stringify(this.state.allBreaks)),
  //     isBreakUpdate: 1,
  //   });
  // };

  // addNewRow = () => {
  //   this.setState((prevState) => ({
  //     addBreaksData: [
  //       ...prevState.addBreaksData,
  //       {
  //         status: 0,
  //         break_minutes: "0",
  //         is_paid: "0",
  //         break_hours: "0",
  //         is_mendatory: "0",
  //       },
  //     ],
  //   }));
  // };

  // handleChangeNewBreaksValue = (e, index) => {
  //   var addBreaksData = this.state.addBreaksData;
  //   var state = e.target.name;
  //   var value = e.target.value;
  //   addBreaksData[index][state] = value;
  //   addBreaksData[index].add_edit_status = "add";
  //   this.setState({
  //     addBreaksData: JSON.parse(JSON.stringify(this.state.addBreaksData)),
  //     isBreakUpdate: 1,
  //   });
  // };

  // deleteRow = (data) => {
  //   this.setState({
  //     deleteModal: true,
  //     deleterowId: data._id,
  //   });
  // };

  // deleteModalClose = () => {
  //   this.setState({
  //     deleteModal: false,
  //   });
  // };

  componentDidUpdate(prevProps) {
    if (this.props.LocationDetaildata !== prevProps.LocationDetaildata) {
      if (this.props.LocationDetaildata.WorkScheduleData) {
        const newWeekDaysTimingsData = [
          ...this.props.LocationDetaildata.WorkScheduleData,
        ].map((item) => {
          var cloneItem = item;
          var schedule_day_start_time = moment(item.schedule_day_start_time)
            .tz(item.schedule_time_zone)
            .format("HH:mm");
          var schedule_day_end_time = moment(item.schedule_day_end_time)
            .tz(item.schedule_time_zone)
            .format("HH:mm");
          cloneItem["schedule_day_start_time"] = schedule_day_start_time;
          cloneItem["schedule_day_end_time"] = schedule_day_end_time;
          return cloneItem;
        });
        this.setState({
          weekDaysTimingsData: newWeekDaysTimingsData,
        });
      }
      this.setState({
        isLoading: false,
        location_timezone:
          this.props.LocationDetaildata.locationDetail?.location_timezone,
        data: this.props.LocationDetaildata.locationDetail,
      });
    }
  }

  // handleChangeData = (e) => {
  //   let state = e.target.name;
  //   let checked = e.target.checked;
  //   this.setState((prevState) => ({
  //     data: {
  //       ...prevState.data,
  //       [state]: checked === true ? 1 : 0,
  //     },
  //   }));
  // };

  // handleChangeDate = (date, state) => {
  //   let d = new Date(date);
  //   this.setState(
  //     (prevState) => ({
  //       data: {
  //         ...prevState.data,
  //         [state]: date,
  //       },
  //     }),
  //     () => {}
  //   );
  // };

  // handleChangeSelectValue = (e) => {
  //   let state = e.target.name;
  //   let value = e.target.value;
  //   this.setState((prevState) => ({
  //     data: {
  //       ...prevState.data,
  //       [state]: value,
  //     },
  //   }));
  // };

  // handleChangeText = (e) => {
  //   let state = e.target.name;
  //   let value = e.target.value;

  //   if (state) {
  //     const validMin = value.match(/^[0-9]+$/);
  //     if (!validMin && value !== "") {
  //       alert("Not a valid number");
  //     }
  //   }
  //   this.setState((prevState) => ({
  //     data: {
  //       ...prevState.data,
  //       [state]: value,
  //     },
  //   }));
  // };

  // handleChangeDays = (e) => {
  //   this.setState({
  //     location_timezone: e.target.value,
  //   });
  // };

  // handleApplyToAll = (e) => {
  //   this.setState({
  //     applyToAll: e.target.checked ? 1 : 0,
  //   });
  // };

  // rangeCalculater = (date, index) => {
  //   if (date === undefined || date === null) {
  //     return false;
  //   }
  //   var formatStart = moment(date.start_time, "hh:mm:ss").format("HH");
  //   var formatEnd = moment(date.end_time, "hh:mm:ss").format("HH");
  //   var formatStartNew = moment(date.start_time, "hh:mm:ss").format("HH:mm");
  //   var formatEndNew = moment(date.end_time, "hh:mm:ss").format("HH:mm");
  //   const startRange = formatStart !== "" ? Number(formatStart) : null;
  //   const endRange = formatEnd !== "" ? Number(formatEnd) : null;
  //   if (startRange === null || endRange === null) {
  //     return false;
  //   }

  //   const inbtwnRange = index >= startRange && index <= endRange ? true : false;

  //   return {
  //     inbtwnRange: inbtwnRange,
  //     startIndex: {
  //       index: startRange,
  //       value: formatStartNew,
  //     },
  //     endIndex: {
  //       index: endRange,
  //       value: formatEndNew,
  //     },
  //   };
  // };

  // handleChangeCheck = (event) => {
  //   this.setState({
  //     checked: event.target.checked,
  //   });
  // };

  // handleClickTwo = (event, index) => {
  //   this.state.getApiResponse.eventsData.filter((item) => {
  //     setTimePreStartNew = moment(item.start_time, "hh:mm:ss").format(
  //       "YYYY-MM-DDhh:mm:ss"
  //     );
  //     setTimePreEndNew = moment(item.end_time, "hh:mm:ss").format(
  //       "YYYY-MM-DDhh:mm:ss"
  //     );
  //     if (item.day_id === index) {
  //       return this.setState({
  //         openLeft: true,
  //         dayState: index,
  //         setTimePreStart: setTimePreStartNew,
  //         setTimePreEnd: setTimePreEndNew,
  //       });

  //       // setTimeNew = moment(this.state.setTime,"hh:mm:ss").format("YYYY-MM-DDhh:mm:ss")
  //       // setTimeEndNew = moment(this.state.setTimeEnd,"hh:mm:ss").format("YYYY-MM-DDhh:mm:ss")
  //     }
  //   });
  // };

  render() {
    const cardBg = "#faf9f9";
    const cardRadius = "12px";
    const date = moment(this.state.startDate).format("YYYY-MM-DD");
    const time = moment(this.state.startTime).format("THH:mm:ssZ");
    const total = date + time;
    const now = moment().hour(0).minute(0);
    const { dropdownData } = this.state;

    const {
      permissionsData,
      classes,
      // LocationDetaildata,
      isAddLoading,
      // timezone,
      // getApiResponse,
    } = this.props;
    var selectedDropdown = dropdownData.find(
      (dropdown) => parseInt(this.state.av_id) === dropdown.id
    );

    return (
      // permissionsData.scheduler_settings ?
      <>
        <Grid container spacing={2} style={{ padding: "8px" }}>
          <TableContainer component={Paper}>
            <Table
              style={{ tableLayout: "fixed" }}
              aria-label="collapsible table"
            >
              <TableHead className={classes.schtabletrhead}>
                <TableRow>
                  <TableCell
                    className={classes.listCell1}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Location
                  </TableCell>
                  <TableCell
                    className={classes.listCell2}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Payrule
                  </TableCell>
                  <TableCell
                    className={classes.listCell3}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Availablity %
                  </TableCell>
                  <TableCell
                    className={classes.listCell6}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Unavailable
                  </TableCell>
                  <TableCell
                    className={classes.listCell5}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Preferred
                  </TableCell>

                  <TableCell
                    className={classes.listCell8}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Available Status
                  </TableCell>
                  <TableCell
                    className={classes.listCell9}
                    style={{ color: "white", fontWeight: "300" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.getApiResponse?.my_avail &&
                  this.props.getApiResponse.my_avail.map((items, index) => {
                    return (
                      <>
                        <TableRow hover>
                          <TableCell className={classes.listCell2} align="left">
                            {items.location_name}
                            {/* <Box display={"flex"} style={{ gap: "0.4rem" }}>

                              <Typography
                                className={classes.subjectName}
                                style={{
                                  color: schedulerColor.main,
                                  opacity: 0.8,
                                }}
                                variant="h6"
                              >
                                {items.location_name}
                              </Typography>
                            </Box> */}
                          </TableCell>
                          <TableCell className={classes.listCell2} align="left">
                            {items.payrule}
                            {/* <VisibilityOffIcon/> */}
                          </TableCell>
                          <TableCell className={classes.listCell3} align="left">
                            <Typography
                              className={classes.subjectName}
                              style={{
                                color: schedulerColor.main,
                                opacity: 0.8,
                              }}
                              variant="h6"
                            >
                              {items.availability}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.listCell4} align="left">
                            <Typography
                              className={classes.subjectName}
                              variant="h6"
                            >
                              {items.unavailable}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.listCell5} align="left">
                            <Typography
                              className={classes.subjectName}
                              style={{
                                color: schedulerColor.main,
                                opacity: 0.8,
                              }}
                              variant="h6"
                            >
                              {items.preferred}
                            </Typography>
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              this.props.handleClickToggle(items.open, index);
                            }}
                            className={classes.listCell8}
                            align="left"
                          >
                            <Box display={"flex"} style={{ gap: "0.4rem" }}>
                              <Button
                                disabled={
                                  permissionsData.scheduler_myavailability_add
                                    ? false
                                    : true
                                }
                                onClick={() => {
                                  this.props.newModalOpen(items);
                                }}
                                style={{
                                  padding: "0px 8px",
                                  border:
                                    permissionsData.scheduler_myavailability_add
                                      ? "1px solid #AA076B"
                                      : "1px solid gray",
                                  color:
                                    permissionsData.scheduler_myavailability_add
                                      ? "#AA076B"
                                      : "gray",
                                  cursor: "pointer",
                                }}
                              >
                                Add
                              </Button>
                              {items.open ? (
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <ExpandLessRoundedIcon
                                    style={{
                                      color: "white",
                                      cursor: "pointer",
                                      background: "rgba(170, 7, 107, 1)",
                                      borderRadius: "13px",
                                      fontSize: "20px",
                                    }}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <ExpandMoreRoundedIcon
                                    style={{
                                      color: "white",
                                      cursor: "pointer",
                                      background: "rgba(170, 7, 107, 1)",
                                      borderRadius: "13px",
                                      fontSize: "20px",
                                    }}
                                  />
                                </Box>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell className={classes.listCell9} align="left">
                            <Typography
                              className={classes.subjectName}
                              style={{
                                color: schedulerColor.main,
                                opacity: 0.8,
                              }}
                              variant="h6"
                            ></Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={7}
                          >
                            <Collapse in={items.open}>
                              <Box
                                sx={{
                                  margin: "0px auto",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item sm={12} className="d-flex">
                                    <TableContainer component={Paper}>
                                      <Table
                                        style={{ tableLayout: "fixed" }}
                                        aria-label="collapsible table"
                                      >
                                        {items?.data?.length === 0 ? (
                                          <Collapse in={items.open}>
                                            <Box
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                margin: "4rem",
                                              }}
                                            >
                                              <Typography variant="h4">
                                                No Availability For{" "}
                                                {items.location_name}
                                              </Typography>
                                            </Box>
                                          </Collapse>
                                        ) : (
                                          <TableBody>
                                            {items?.effective_dates?.length >
                                              0 &&
                                              items.effective_dates?.map(
                                                (date, indexx) => {
                                                  return (
                                                    <>
                                                      <TableRow
                                                        style={{
                                                          backgroundColor:
                                                            "rgba(170, 7, 107, 0.1)",
                                                        }}
                                                        hover
                                                      >
                                                        <Collapse
                                                          in={items.open}
                                                          style={{
                                                            overFlow: "scroll",
                                                          }}
                                                        >
                                                          <TableCell
                                                            style={{
                                                              paddingBottom: 0,
                                                              paddingTop: 0,
                                                            }}
                                                            // colSpan={7}
                                                          >
                                                            <Grid
                                                              container
                                                              spacing={2}
                                                            >
                                                              <Grid
                                                                item
                                                                sm={12}
                                                                className="d-flex"
                                                              >
                                                                <div
                                                                  onClick={() =>
                                                                    this.props.handleClickToggleMYInside(
                                                                      date.openMYInside,
                                                                      indexx,
                                                                      items.lid,
                                                                      date.av_id
                                                                    )
                                                                  }
                                                                >
                                                                  <TableCell align="left">
                                                                    {" "}
                                                                    {date.openMYInside ? (
                                                                      <Box>
                                                                        <ExpandLessRoundedIcon
                                                                          style={{
                                                                            color:
                                                                              "white",
                                                                            cursor:
                                                                              "pointer",
                                                                            background:
                                                                              "rgba(170, 7, 107, 1)",
                                                                            borderRadius:
                                                                              "13px",
                                                                            fontSize:
                                                                              "20px",
                                                                          }}
                                                                        />
                                                                      </Box>
                                                                    ) : (
                                                                      <Box>
                                                                        <ExpandMoreRoundedIcon
                                                                          style={{
                                                                            color:
                                                                              "white",
                                                                            cursor:
                                                                              "pointer",
                                                                            background:
                                                                              "rgba(170, 7, 107, 1)",
                                                                            borderRadius:
                                                                              "13px",
                                                                            fontSize:
                                                                              "20px",
                                                                          }}
                                                                        />
                                                                      </Box>
                                                                    )}
                                                                  </TableCell>
                                                                  <TableCell align="left">
                                                                    <Typography
                                                                      style={{
                                                                        lineHeight:
                                                                          "25px",
                                                                      }}
                                                                      className={
                                                                        classes.subjectName
                                                                      }
                                                                      variant="h6"
                                                                    >
                                                                      {moment(
                                                                        date.effective_start_date
                                                                      ).format(
                                                                        "YYYY-MM-DD"
                                                                      )}{" "}
                                                                      {date.avail_status ===
                                                                        2 ||
                                                                      date.avail_status ===
                                                                        1 ||
                                                                      date.avail_status ===
                                                                        0
                                                                        ? "to"
                                                                        : "-"}{" "}
                                                                      {date.avail_status ===
                                                                        2 ||
                                                                      date.avail_status ===
                                                                        1 ||
                                                                      date.avail_status ===
                                                                        0
                                                                        ? "ongoing"
                                                                        : moment(
                                                                            date.effective_end_date
                                                                          ).format(
                                                                            "YYYY-MM-DD"
                                                                          )}
                                                                    </Typography>
                                                                  </TableCell>
                                                                  <TableCell align="left">
                                                                    {date.is_publish ===
                                                                    1 ? (
                                                                      <Button
                                                                        style={{
                                                                          background:
                                                                            "#AA076B",
                                                                          color:
                                                                            "white",
                                                                          padding:
                                                                            "0px 8px",
                                                                          textTransform:
                                                                            "capitalize",
                                                                        }}
                                                                        onClick={() =>
                                                                          this.props.publishEffectiveDate(
                                                                            date.av_id,
                                                                            items
                                                                          )
                                                                        }
                                                                        className={
                                                                          classes.subjectName
                                                                        }
                                                                      >
                                                                        Publish
                                                                      </Button>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                  </TableCell>
                                                                  <TableCell align="left">
                                                                    <Typography
                                                                      // style={{ marginLeft: date.is_publish === 1 ? "774px" : "830px" }}
                                                                      className={
                                                                        classes.subjectName
                                                                      }
                                                                      variant="h6"
                                                                    >
                                                                      {date.avail_status ===
                                                                      0 ? (
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "#AA076B",
                                                                            fontWeight: 700,
                                                                          }}
                                                                        >
                                                                          Draft
                                                                        </span>
                                                                      ) : date.avail_status ===
                                                                        1 ? (
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "#AAB80D",
                                                                            fontWeight: 700,
                                                                          }}
                                                                        >
                                                                          Submitted
                                                                        </span>
                                                                      ) : date.avail_status ===
                                                                        2 ? (
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "#1B693F",
                                                                            fontWeight: 700,
                                                                          }}
                                                                        >
                                                                          Approved
                                                                        </span>
                                                                      ) : date.avail_status ===
                                                                        3 ? (
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "#AA0707",
                                                                            fontWeight: 700,
                                                                          }}
                                                                        >
                                                                          Rejected
                                                                        </span>
                                                                      ) : (
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "#AA0707",
                                                                            fontWeight: 700,
                                                                          }}
                                                                        >
                                                                          Expired
                                                                        </span>
                                                                      )}
                                                                    </Typography>
                                                                  </TableCell>
                                                                </div>
                                                              </Grid>
                                                            </Grid>
                                                          </TableCell>
                                                        </Collapse>
                                                      </TableRow>

                                                      <TableRow
                                                        style={{
                                                          backgroundColor:
                                                            "white",
                                                        }}
                                                        hover
                                                      >
                                                        <TableCell
                                                          style={{
                                                            padding: "0",
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <Collapse
                                                            in={
                                                              date.openMYInside
                                                            }
                                                          >
                                                            <Box
                                                              sx={{
                                                                margin:
                                                                  "0px auto",
                                                                display: "flex",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              <Grid
                                                                container
                                                                spacing={2}
                                                              >
                                                                <Grid
                                                                  item
                                                                  md={12}
                                                                  className="d-flex"
                                                                >
                                                                  <Grid
                                                                    item
                                                                    md={12}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        marginTop:
                                                                          "15px",
                                                                        padding:
                                                                          "0px 16px 12px 16px",
                                                                      }}
                                                                    >
                                                                      <Box
                                                                        display="flex"
                                                                        style={{
                                                                          gap: "2vw",
                                                                          flexWrap:
                                                                            "wrap",
                                                                        }}
                                                                      >
                                                                        {date?.schDay?.map(
                                                                          (
                                                                            weekDay,
                                                                            index
                                                                          ) => {
                                                                            if (
                                                                              weekDay.is_wholeday ===
                                                                              1
                                                                            ) {
                                                                              var unavailable_start_timetemp =
                                                                                new Date(
                                                                                  weekDay.unavailable_start_time
                                                                                );
                                                                              var unavailable_start_time =
                                                                                moment(
                                                                                  weekDay.unavailable_start_time
                                                                                ).tz(
                                                                                  this
                                                                                    .props
                                                                                    .timezone[0]
                                                                                    ?.location_timezone
                                                                                );

                                                                              var unavailable_end_timetemp =
                                                                                new Date(
                                                                                  weekDay.unavailable_end_time
                                                                                );
                                                                              var unavailable_end_time =
                                                                                moment(
                                                                                  weekDay.unavailable_end_time
                                                                                ).tz(
                                                                                  this
                                                                                    .props
                                                                                    .timezone[0]
                                                                                    ?.location_timezone
                                                                                );

                                                                              var prefered_start_timetemp =
                                                                                new Date(
                                                                                  weekDay.prefered_start_time
                                                                                );
                                                                              var prefered_start_time =
                                                                                moment(
                                                                                  weekDay.prefered_start_time
                                                                                ).tz(
                                                                                  this
                                                                                    .props
                                                                                    .timezone[0]
                                                                                    ?.location_timezone
                                                                                );

                                                                              var prefered_end_timetemp =
                                                                                new Date(
                                                                                  weekDay.prefered_end_time
                                                                                );
                                                                              var prefered_end_time =
                                                                                moment(
                                                                                  weekDay.prefered_end_time
                                                                                ).tz(
                                                                                  this
                                                                                    .props
                                                                                    .timezone[0]
                                                                                    ?.location_timezone
                                                                                );
                                                                            } else {
                                                                              var unavailable_start_timetemp =
                                                                                new Date(
                                                                                  weekDay.unavailable_start_time
                                                                                );
                                                                              var unavailable_start_time =
                                                                                // moment(weekDay.unavailable_start_time)
                                                                                // .tz(this.props.timezone[0].location_timezone)
                                                                                unavailable_start_timetemp.toLocaleString(
                                                                                  "en-US",
                                                                                  {
                                                                                    timeZone:
                                                                                      this
                                                                                        .props
                                                                                        .timezone[0]
                                                                                        ?.location_timezone,
                                                                                  }
                                                                                );
                                                                              var unavailable_end_timetemp =
                                                                                new Date(
                                                                                  weekDay.unavailable_end_time
                                                                                );
                                                                              var unavailable_end_time =
                                                                                // moment(weekDay.unavailable_end_time)
                                                                                // .tz(this.props.timezone[0].location_timezone)
                                                                                unavailable_end_timetemp.toLocaleString(
                                                                                  "en-US",
                                                                                  {
                                                                                    timeZone:
                                                                                      this
                                                                                        .props
                                                                                        .timezone[0]
                                                                                        ?.location_timezone,
                                                                                  }
                                                                                );

                                                                              var prefered_start_timetemp =
                                                                                new Date(
                                                                                  weekDay.prefered_start_time
                                                                                );
                                                                              var prefered_start_time =
                                                                                // moment(weekDay.prefered_start_time)
                                                                                // .tz(this.props.timezone[0].location_timezone)
                                                                                prefered_start_timetemp.toLocaleString(
                                                                                  "en-US",
                                                                                  {
                                                                                    timeZone:
                                                                                      this
                                                                                        .props
                                                                                        .timezone[0]
                                                                                        ?.location_timezone,
                                                                                  }
                                                                                );

                                                                              var prefered_end_timetemp =
                                                                                new Date(
                                                                                  weekDay.prefered_end_time
                                                                                );
                                                                              var prefered_end_time =
                                                                                // moment(weekDay.prefered_end_time)
                                                                                // .tz(this.props.timezone[0].location_timezone)
                                                                                prefered_end_timetemp.toLocaleString(
                                                                                  "en-US",
                                                                                  {
                                                                                    timeZone:
                                                                                      this
                                                                                        .props
                                                                                        .timezone[0]
                                                                                        ?.location_timezone,
                                                                                  }
                                                                                );
                                                                            }
                                                                            return (
                                                                              <div
                                                                                key={
                                                                                  index
                                                                                }
                                                                                style={{
                                                                                  marginBottom:
                                                                                    "40px",
                                                                                }}
                                                                              >
                                                                                {weekDay.unavailable_start_time ===
                                                                                  null &&
                                                                                weekDay.unavailable_end_time ===
                                                                                  null &&
                                                                                weekDay.prefered_start_time ===
                                                                                  null &&
                                                                                weekDay.prefered_end_time ===
                                                                                  null ? (
                                                                                  <div
                                                                                    style={{
                                                                                      height:
                                                                                        "190px",
                                                                                      width:
                                                                                        "150px",
                                                                                    }}
                                                                                    className={
                                                                                      classes.divWdth
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      onClick={() =>
                                                                                        permissionsData.scheduler_myavailability_add &&
                                                                                        (date.avail_status ===
                                                                                          0 ||
                                                                                          date.avail_status ===
                                                                                            1)
                                                                                          ? this.props.handlePopupModal(
                                                                                              weekDay,
                                                                                              index,
                                                                                              items.location_name,
                                                                                              "add"
                                                                                            )
                                                                                          : ""
                                                                                      }
                                                                                      style={{
                                                                                        display:
                                                                                          "flex",
                                                                                        alignItems:
                                                                                          "center",
                                                                                        justifyContent:
                                                                                          "center",
                                                                                        height:
                                                                                          "100%",
                                                                                      }}
                                                                                    >
                                                                                      {/* Add button */}
                                                                                      {date.avail_status ===
                                                                                        0 ||
                                                                                      date.avail_status ===
                                                                                        1 ? (
                                                                                        <button
                                                                                          style={{
                                                                                            background:
                                                                                              permissionsData.scheduler_myavailability_add
                                                                                                ? "#AA076B"
                                                                                                : "gray",
                                                                                            color:
                                                                                              "white",
                                                                                            borderRadius:
                                                                                              "8px",
                                                                                            padding:
                                                                                              "4px",
                                                                                            width:
                                                                                              "46px",
                                                                                          }}
                                                                                        >
                                                                                          Add
                                                                                        </button>
                                                                                      ) : (
                                                                                        ""
                                                                                      )}

                                                                                      {date.avail_status ===
                                                                                        2 ||
                                                                                      date.avail_status ===
                                                                                        3 ||
                                                                                      date.avail_status ===
                                                                                        4 ? (
                                                                                        <button
                                                                                          style={{
                                                                                            background:
                                                                                              "none",
                                                                                            color:
                                                                                              "#AA076B",
                                                                                            padding:
                                                                                              "4px",
                                                                                          }}
                                                                                        >
                                                                                          Available
                                                                                          whole
                                                                                          day
                                                                                        </button>
                                                                                      ) : (
                                                                                        ""
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                ) : (
                                                                                  <div
                                                                                    style={{
                                                                                      height:
                                                                                        "190px",
                                                                                      width:
                                                                                        "150px",
                                                                                    }}
                                                                                    className={
                                                                                      classes.divWdth
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      onClick={() =>
                                                                                        permissionsData.scheduler_myavailability_edit &&
                                                                                        (date.avail_status ===
                                                                                          0 ||
                                                                                          date.avail_status ===
                                                                                            1)
                                                                                          ? this.props.handlePopupModal(
                                                                                              weekDay,
                                                                                              index,
                                                                                              items.location_name,
                                                                                              "edit"
                                                                                            )
                                                                                          : ""
                                                                                      }
                                                                                    >
                                                                                      <>
                                                                                        {weekDay.unavailable_start_time ===
                                                                                          null &&
                                                                                        weekDay.unavailable_start_time ===
                                                                                          null ? (
                                                                                          ""
                                                                                        ) : (
                                                                                          <div
                                                                                            style={{
                                                                                              background:
                                                                                                "#AA076B",
                                                                                            }}
                                                                                          >
                                                                                            <Typography
                                                                                              style={{
                                                                                                padding:
                                                                                                  "3px",
                                                                                                marginTop:
                                                                                                  "23px",
                                                                                                color:
                                                                                                  "white",
                                                                                                textAlign:
                                                                                                  "center",
                                                                                                fontSize:
                                                                                                  "13px",
                                                                                              }}
                                                                                            >
                                                                                              <span>
                                                                                                Unavailable
                                                                                              </span>
                                                                                              <br />
                                                                                              {moment(
                                                                                                unavailable_start_time
                                                                                              ).format(
                                                                                                "hh:mm A"
                                                                                              )}{" "}
                                                                                              -{" "}
                                                                                              {moment(
                                                                                                unavailable_end_time
                                                                                              ).format(
                                                                                                "hh:mm A"
                                                                                              )}
                                                                                            </Typography>
                                                                                          </div>
                                                                                        )}
                                                                                        {weekDay.prefered_start_time ===
                                                                                          null &&
                                                                                        weekDay.prefered_end_time ===
                                                                                          null ? (
                                                                                          ""
                                                                                        ) : (
                                                                                          <div
                                                                                            style={{
                                                                                              background:
                                                                                                "rgba(170, 7, 107, 0.1)",
                                                                                            }}
                                                                                          >
                                                                                            <Typography
                                                                                              style={{
                                                                                                padding:
                                                                                                  "3px",
                                                                                                marginTop:
                                                                                                  "23px",
                                                                                                color:
                                                                                                  "#AA076B",
                                                                                                textAlign:
                                                                                                  "center",
                                                                                                fontSize:
                                                                                                  "13px",
                                                                                                marginBottom:
                                                                                                  "31px",
                                                                                              }}
                                                                                            >
                                                                                              Preferred
                                                                                              <br />
                                                                                              {moment(
                                                                                                prefered_start_time
                                                                                              ).format(
                                                                                                "hh:mm A"
                                                                                              )}{" "}
                                                                                              -{" "}
                                                                                              {moment(
                                                                                                prefered_end_time
                                                                                              ).format(
                                                                                                "hh:mm A"
                                                                                              )}
                                                                                            </Typography>
                                                                                          </div>
                                                                                        )}
                                                                                      </>
                                                                                    </div>
                                                                                  </div>
                                                                                )}

                                                                                <Typography
                                                                                  style={{
                                                                                    marginBottom:
                                                                                      "0.4rem",
                                                                                  }}
                                                                                  align="center"
                                                                                  variant="h6"
                                                                                >
                                                                                  {weekDay.day_id ===
                                                                                  0
                                                                                    ? "Sunday"
                                                                                    : weekDay.day_id ===
                                                                                      1
                                                                                    ? "Monday"
                                                                                    : weekDay.day_id ===
                                                                                      2
                                                                                    ? "Tuesday"
                                                                                    : weekDay.day_id ===
                                                                                      3
                                                                                    ? "Wednesday"
                                                                                    : weekDay.day_id ===
                                                                                      4
                                                                                    ? "Thrusday"
                                                                                    : weekDay.day_id ===
                                                                                      5
                                                                                    ? "Friday"
                                                                                    : weekDay.day_id ===
                                                                                      6
                                                                                    ? "Saturday"
                                                                                    : ""}
                                                                                </Typography>
                                                                              </div>
                                                                            );
                                                                          }
                                                                        )}
                                                                      </Box>
                                                                    </div>
                                                                  </Grid>
                                                                </Grid>
                                                              </Grid>
                                                            </Box>
                                                          </Collapse>
                                                        </TableCell>
                                                      </TableRow>
                                                    </>
                                                  );
                                                }
                                              )}
                                          </TableBody>
                                        )}
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.schedulerSettingsLocationdata.isLoading,
  LocationDetaildata: state.schedulerSettingsLocationdata.LocationDetaildata,
  WorkScheduleData: state.schedulerSettingsLocationdata.WorkScheduleData,
  WorkSchedule: state.schedulerSettingsLocationdata.WorkSchedule,
  locationData: state.settingsLocation.locationData,
});

const ConnectWith = connect(mapStateToProps, {
  getScheduleData,
  updateScheduleData,
})(Scheduling);
export default withStyles(styles)(ConnectWith);
